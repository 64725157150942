export const authConstants = {
  REGISTER_REQUEST: 'AUTH_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'AUTH_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'AUTH_REGISTER_FAILURE',

  SIGN_IN_REQUEST: 'AUTH_SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: 'AUTH_SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'AUTH_SIGN_IN_FAILURE',

  SIGN_OUT_REQUEST: 'AUTH_SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCESS: 'AUTH_SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'AUTH_SIGN_OUT_FAILURE',

  RESEND_EMAIL_REQUEST: 'AUTH_RESEND_EMAIL_REQUEST',
  RESEND_EMAIL_SUCCESS: 'AUTH_RESEND_EMAIL_SUCCESS',
  RESEND_EMAIL_FAILURE: 'AUTH_RESEND_EMAIL_FAILURE',

  UPDATE_EMAIL_REQUEST: 'AUTH_UPDATE_EMAIL_REQUEST',
  UPDATE_EMAIL_SUCCESS: 'AUTH_UPDATE_EMAIL_SUCCESS',
  UPDATE_EMAIL_FAILURE: 'AUTH_UPDATE_EMAIL_FAILURE',

  VALIDATE_PHONE_REQUEST: 'AUTH_VALIDATE_PHONE_REQUEST',
  VALIDATE_PHONE_SUCCESS: 'AUTH_VALIDATE_PHONE_SUCCESS',
  VALIDATE_PHONE_FAILURE: 'AUTH_VALIDATE_PHONE_FAILURE',

  UPDATE_PHONE_REQUEST: 'AUTH_UPDATE_PHONE_REQUEST',
  UPDATE_PHONE_SUCCESS: 'AUTH_UPDATE_PHONE_SUCCESS',
  UPDATE_PHONE_FAILURE: 'AUTH_UPDATE_PHONE_FAILURE',

  UPDATE_PIN_REQUEST: 'AUTH_UPDATE_PIN_REQUEST',
  UPDATE_PIN_SUCCESS: 'AUTH_UPDATE_PIN_SUCCESS',
  UPDATE_PIN_FAILURE: 'AUTH_UPDATE_PIN_FAILURE',

  PIN_CONFIRMATION_REQUEST: 'AUTH_PIN_CONFIRMATION_REQUEST',
  PIN_CONFIRMATION_SUCCESS: 'AUTH_PIN_CONFIRMATION_SUCCESS',
  PIN_CONFIRMATION_FAILURE: 'AUTH_PIN_CONFIRMATION_FAILURE',

  UPDATE_PASSWORD_REQUEST: 'AUTH_UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'AUTH_UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'AUTH_UPDATE_PASSWORD_FAILURE',

  UPDATE_NAME_REQUEST: 'AUTH_UPDATE_NAME_REQUEST',
  UPDATE_NAME_SUCCESS: 'AUTH_UPDATE_NAME_SUCCESS',
  UPDATE_NAME_FAILURE: 'AUTH_UPDATE_NAME_FAILURE',

  FORGOT_PASSWORD_REQUEST: 'AUTH_FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'AUTH_FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'AUTH_FORGOT_PASSWORD_FAILURE',

  FORGOT_PIN_REQUEST: 'AUTH_FORGOT_PIN_REQUEST',
  FORGOT_PIN_SUCCESS: 'AUTH_FORGOT_PIN_SUCCESS',
  FORGOT_PIN_FAILURE: 'AUTH_FORGOT_PIN_FAILURE',

  DELETE_ACCOUNT_REQUEST: 'AUTH_DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: 'AUTH_DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILURE: 'AUTH_DELETE_ACCOUNT_FAILURE',

  RESET_PASSWORD_REQUEST: 'AUTH_RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'AUTH_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'AUTH_RESET_PASSWORD_FAILURE',

  SMS_CONFIRMATION_REQUEST: 'AUTH_SMS_CONFIRMATION_REQUEST',
  SMS_CONFIRMATION_SUCCESS: 'AUTH_SMS_CONFIRMATION_SUCCESS',
  SMS_CONFIRMATION_FAILURE: 'AUTH_SMS_CONFIRMATION_FAILURE',

  EMAIL_CONFIRMATION_REQUEST: 'AUTH_EMAIL_CONFIRMATION_REQUEST',
  EMAIL_CONFIRMATION_SUCCESS: 'AUTH_EMAIL_CONFIRMATION_SUCCESS',
  EMAIL_CONFIRMATION_FAILURE: 'AUTH_EMAIL_CONFIRMATION_FAILURE',

  CONFIRM_BCMK_REQUEST: 'AUTH_CONFIRM_BCMK_REQUEST',
  CONFIRM_BCMK_SUCCESS: 'AUTH_CONFIRM_BCMK_SUCCESS',
  CONFIRM_BCMK_FAILURE: 'AUTH_CONFIRM_BCMK_FAILURE',

  TRIGGER_ACCOUNT_RESET_REQUEST: 'AUTH_TRIGGER_ACCOUNT_RESET_REQUEST',
  TRIGGER_ACCOUNT_RESET_SUCCESS: 'AUTH_TRIGGER_ACCOUNT_RESET_SUCCESS',
  TRIGGER_ACCOUNT_RESET_FAILURE: 'AUTH_TRIGGER_ACCOUNT_RESET_FAILURE',

  RESET_ACCOUNT_REQUEST: 'AUTH_RESET_ACCOUNT_REQUEST',
  RESET_ACCOUNT_SUCCESS: 'AUTH_RESET_ACCOUNT_SUCCESS',
  RESET_ACCOUNT_FAILURE: 'AUTH_RESET_ACCOUNT_FAILURE',

  CONTINUE_WITH_GOOGLE_REQUEST: 'AUTH_CONTINUE_WITH_GOOGLE_REQUEST',
  CONTINUE_WITH_GOOGLE_SUCCESS: 'AUTH_CONTINUE_WITH_GOOGLE_SUCCESS',
  CONTINUE_WITH_GOOGLE_FAILURE: 'AUTH_CONTINUE_WITH_GOOGLE_FAILURE',

  SET_CURRENT_STEP_REQUEST: 'AUTH_SET_CURRENT_STEP_REQUEST',
  SET_AUTH_PHONE_NUMBER: 'AUTH_SET_AUTH_PHONE_NUMBER',
  SET_AUTH_EMAIL: 'AUTH_SET_AUTH_EMAIL',

  REPORT_CLIENT_ERROR_REQUEST: 'AUTH_REPORT_CLIENT_ERROR_REQUEST',
  ACKNOWLEDGE_MK_REQUEST: 'AUTH_ACKNOWLEDGE_MK_REQUEST',
};
