import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['navigator', 'htmlTag', 'path', 'subdomain'],
      caches: [] // Disable caching
    },
    resources: {
      en: {
        translation: {
          continueWithGoogle: "Continue with Google",
          continueWithEmail: "Continue with Email",
          byContinuingYouAgreeToThe: "By continuing you agree to the",
          termsOfService: "Terms of Service",
          and: "and",
          privacyPolicy: "Privacy Policy",
          emailPlaceholder: "you@email.com",
          goBack: "Go Back",
          thatDoesntMatchWhatWeSent: "That doesn't match what we sent",
          weJustSentACodeTo: "we just sent a code to",
          enterItHereToContinue: "Enter it here to continue",
          wrongEmail: "Wrong email",
          confirm: "Confirm",
          welcomeToVALT: "Welcome to VALT",
          welcomeToVALTMarketplace: "Welcome to VALT Marketplace",
          marketplaceDataKeyDisclosure: 'Here is your Data Key. It lets you access the data you purchase, so copy/paste this somewhere before proceeding and never share it. This only appears on signup. If lost, the data purchased by this account will be irrecoverable',
          dataKeyDisclosure: "Here is your Data Key. You may need this to link devices and recover data, so copy/paste this somewhere before proceeding and never share it. This only appears on signup. If lost, this account's data will be irrecoverable",
          copy: "Copy",
          copied: "Copied",
          ok: "Ok",
          withThisAppYoullBeAbleTo: "With this app, you'll be able to",
          secure: "Secure",
          interactWith: "Interact with",
          monetize: "Monetize",
          yourData: 'your data',
          howVALTSecuresData: "VALT allows you to capture the data you create on your apps and services, like GPS data, email, browsing history, purchase data, and much more",
          howVALTAllowsInteraction: "Our AI assistant and personal data timeline enable you to refer back to the data you've created and glean insights about yourself previously only accessible to Google and digital marketers",
          howVALTMonetizesData: "Given enough users, our Personal Data Marketplace will soon allow you to sell your data for the first time. Refer your friends and help kickstart the movement today",
          next: "Next",
          back: "Back",
          connectdVPN: "Connect dVPN",
          connectdVPNInstructions: "Connect to the dVPN from the navigation bar at the bottom of the screen",
          connectDataSources: "Connect Data Sources",
          connectDataSourcesInstructions: "Add new data sources by accessing the settings menu in the top-left",
          gotIt: "Got It",
          noDataCollectedFor: "No Data Collected for",
          tapForFastestConnections: "Tap for Fastest Connections",
          gettingData: "Getting Data",
          secureMoreDataSources: "Secure More Data Sources",
          upgradeVALT: "Upgrade to VALT+",
          changeDate: "Change Date",
          account: "Account",
          connectedApps: "Connected Apps",
          supportAndFAQ: "Support & FAQ",
          logOut: "Log Out",
          copywright: "© 2025 HashCash, Inc",
          EULA: "EULA",
          timeline: "Timeline",
          chat: "Chat",
          moreInfo: "More Info",
          download: "Download",
          upload: "Upload",
          youAreCurrentlyConnectedTo: "You're currently connected to",
          updateConnection: "Update Connection",
          hey: "Hey",
          welcomeBack: "Welcome Back",
          iveDetected: "I've detected",
          leaksAssociatedWithYourData: "Leaks associated with your data",
          youreNotConnectedToOurdVPNHeresAFastConnectionForYouIn: "You're not connected to our dVPN. Here's a fast connection for you in",
          yourAccount: "Your Account",
          firstName: "First Name",
          lastName: "Last Name",
          email: "Email",
          phone: "Phone",
          notProvided: "Not Provided",
          billing: "Billing",
          deleteAccount: "Delete Account",
          areYouSure: "Are You Sure",
          yes: "Yes",
          waitNevermind: "Wait, Nevermind",
          version: "Version",
          takeControlOfYourPersonalDataTodayWithVALTPlus: "Take control of your personal data today with VALT+",
          annualPlan: "Annual Plan",
          currentPlan: "Current Plan",
          whatsIncluded: "What's Included",
          decentralizedVPNForSecureBrowsing: "Decentralized VPN for Secure Browsing",
          dVPNForUnlimitedDevices: "dVPN for Unlimited Devices",
          advancedBackupTools: "Advanced Backup Tools",
          fullPersonalDataTimelineIntegratedWithDozensOfApps: "Full Personal Data Timeline Integrated With Dozens of Apps",
          dataCleanup: "Continuous Data Cleanup",
          VALTWillForceBrokers: "VALT Will Force Data Brokers to Delete Your Personal Information to Reduce Spam Calls and Emails",
          monthlyPlan: "Monthly Plan",
          twoYearPlan: "2-Year Plan",
          billedEveryTwoYears: "Billed Every Two Years",
          billedAnnually: "Billed Annually",
          select: "Select",
          selectPaymentMethod: "Select Payment Method",
          creditOrDebitCard: "Credit or Debit Card",
          introPriceDisclosure: "The introductory price is valid for the first term of your subscription. Savings granted by the introductory price are compared to the current renewal price",
          planUpdated: "Plan Updated",
          somethingWentWrong: "Something went wrong. Please try again later",
          updatePlan: "Update Plan",
          welcomeToVALTPlus: "Welcome to VALT+",
          weNeedToCollectDetailsToCleanupData: "We need to collect a few details to get started on cleaning up your data",
          pleaseEnterYourFirstName: "Please Enter Your First Name",
          pleaseEnterYourLastName: "Please Enter Your Last Name",
          pleaseEnterYourPostalCode: "If you're in the US, please enter your postal code - otherwise put 0",
          pleaseEnterYourBirthday: "Please Enter Your Birthday",
          pleaseEnterYourPhoneNumber: "Please Enter Your Phone Number",
          aboutVALTPlus: "About VALT+",
          aboutVALTPlusDetails: "VALT offers a suite of tools to help you manage your data. By subscribing today,\n you're helping us build our Personal Data Marketplace where you can get paid for your data. \n VALT never has access to the data you collect on the VALT App.",
          planSummary: "Plan Summary",
          unitedStates: "United States",
          salesTax: "Sales Tax",
          total: "Total",
          taxCountry: "Tax Country",
          oneyearplan: "1-Year Plan",
          twoyearplan: "2-Year Plan",
          monthlyplan: "Monthly Plan",
          unlimiteddVPNAccess: "Unlimited dVPN Access",
          fullPersonalDataTimeline: "Full Personal Data Timeline",
          constantDataCleanup: "24/7 Data Cleanup",
          serverIsUnavailable: "Server is Unavailable",
          tapToConnect: "Tap to Connect",
          connecting: "Connecting",
          tapToDisconnect: "Tap to Disconnect",
          connected: "Connected",
          returnToCurrentConnection: "Return to Current Connection",
          disconnecting: "Disconnecting",
          connectAccounts: "Connect Accounts",
          connectAccountsDisclosure: "Connect your accounts to secure your data. By continuing, you agree to the privacy policy and terms of service.",
          thisDataSourceIsOnlyAvailableOnMobileDevices: "This data source is only available on mobile devices",
          show: "Show",
          bank: "Bank",
          onDashboard: "on Dashboard",
          reauthorize: "Reauthorize",
          disconnect: "Disconnect",
          downloadTheCromeExtensionFrom: "Download the Chrome Extension on your computer from",
          andEnterTheFollowingCode: "and enter the following code",
          linkDevice: "Link Device",
          toAccessYourDataFromThisDevice: "To access your data from this device, link your account with your Data Key",
          linkAccount: "Link Account",
          lostYourDataKey: "Lost your Data Key",
          getNewKey: "Get New Key",
          signOut: "Sign Out",
          accessYourDataOnDesktop: "To access your data on desktop, link your account with your Data Key",
          dataKey: "Data Key",
          enterYourDataKey: "Enter your Data Key",
          paste: "Paste",
          cancel: "Cancel",
          continue: "Continue",
          confirmDevice: "Confirm Device",
          weJustSentYouAnEmailToConfirmThisDevice: "We just sent you an email to confirm this device",
          afterRespondingToEmail: "After responding to the email, close and reopen the app to gain access",
          checkingForAccess: "Checking for access",
          wrongDevice: "Wrong Device",
          thisDeviceIsCurrentlyConnectedToAnotherVALTAccount: "This device is currently linked with another VALT account",
          enterCode: "Enter code",
          weJustEmailedAccountReset: "We just emailed you an account reset code. Enter it here to continue",
          accountResetDisclosure: "Please note that after resetting your account, all data collected with the previous Data Key will not be retrievable, and you will need to sign back in to all devices with the new Data Key",
          resetAccount: "Reset Account",
          theCodeYouProvidedWasIncorrect: "The code you provided was incorrect",
          resetDevice: "Reset Device",
          VALTPlusDashboard: "VALT+ Dashboard",
          initDataCleanupMessage: "We're getting started on cleaning up your data. Check back here soon. Please note that data cleanup services are currently only supported in the US.",
          selectDisplayAccount: "Select Display Account",
          wereSendingYouToTheLoginPageForThisApp: "We're sending you to the login page for this app",
          connectAppSuccess: "Confirmed. Return to your VALT App for changes to take effect",
          save74: "(Save 74%)",
          save66: "(Save 66%)",
          save68: "(Save 68%)",
          save60: "(Save 60%)",
          save20: "(Save 20%)",
          friend: 'friend',
          youJustArrived: "You just arrived",
          youArrived: "You arrived",
          ago: "ago",
          oneSecond: "1 second",
          oneMinute: "1 minute",
          oneHour: '1 hour',
          seconds: "seconds",
          minutes: "minutes",
          hours: 'hours',
          youVisitedThisLocation: "You visited this location",
          youSpent: "You spent",
          near: "near",
          thisLocation: 'this location',
          youTravelledFor: "You travelled for",
          towards: "towards",
          earth: "Somewhere on Earth",
          at: 'at',
          Georgia: "Georgia",
          Colombia: "Colombia",
          SriLanka: "Sri Lanka",
          UnitedArabEmirates: "United Arab Emirates",
          Canada: "Canada",
          UnitedStates: "United States",
          Slovenia: "Slovenia",
          Belgium: "Belgium",
          Bahamas: "Bahamas",
          NorthMacedonia: "North Macedonia",
          Ecuador: "Ecuador",
          Malaysia: "Malaysia",
          Montenegro: "Montenegro",
          Azerbaijan: "Azerbaijan",
          Jamaica: "Jamaica",
          Kazakhstan: "Kazakhstan",
          Latvia: "Latvia",
          Argentina: "Argentina",
          Russia: "Russia",
          TheNetherlands: "The Netherlands",
          Venezuela: "Venezuela",
          Germany: "Germany",
          Turkey: "Turkey",
          Vietnam: "Vietnam",
          Luxembourg: "Luxembourg",
          Thailand: "Thailand",
          HongKong: "Hong Kong",
          Romania: "Romania",
          Monaco: "Monaco",
          Greece: "Greece",
          IsleofMan: "Isle of Man",
          Pakistan: "Pakistan",
          Uruguay: "Uruguay",
          Armenia: "Armenia",
          Liechtenstein: "Liechtenstein",
          Qatar: "Qatar",
          Switzerland: "Switzerland",
          Egypt: "Egypt",
          Bulgaria: "Bulgaria",
          Cyprus: "Cyprus",
          Lithuania: "Lithuania",
          Panama: "Panama",
          Croatia: "Croatia",
          Slovakia: "Slovakia",
          Sweden: "Sweden",
          Philippines: "Philippines",
          Macao: "Macao",
          Austria: "Austria",
          Portugal: "Portugal",
          Algeria: "Algeria",
          Australia: "Australia",
          SouthAfrica: "South Africa",
          NewZealand: "New Zealand",
          SaudiArabia: "Saudi Arabia",
          Bahrain: "Bahrain",
          China: "China",
          Estonia: "Estonia",
          Morocco: "Morocco",
          Cambodia: "Cambodia",
          SouthKorea: "South Korea",
          Singapore: "Singapore",
          Ireland: "Ireland",
          Iceland: "Iceland",
          Türkiye: "Türkiye",
          Guatemala: "Guatemala",
          PuertoRico: "Puerto Rico",
          Finland: "Finland",
          Greenland: "Greenland",
          Indonesia: "Indonesia",
          UnitedKingdom: "United Kingdom",
          Serbia: "Serbia",
          Nigeria: "Nigeria",
          BosniaandHerzegovina: "Bosnia and Herzegovina",
          Poland: "Poland",
          Netherlands: "Netherlands",
          Italy: "Italy",
          France: "France",
          Denmark: "Denmark",
          Israel: "Israel",
          Mexico: "Mexico",
          Norway: "Norway",
          Chile: "Chile",
          Malta: "Malta",
          Ukraine: "Ukraine",
          Brazil: "Brazil",
          Hungary: "Hungary",
          Japan: "Japan",
          Libya: "Libya",
          Nepal: "Nepal",
          Andorra: "Andorra",
          Moldova: "Moldova",
          Kenya: "Kenya",
          Albania: "Albania",
          India: "India",
          Taiwan: "Taiwan",
          Peru: "Peru",
          Zambia: "Zambia",
          Bangladesh: "Bangladesh",
          Czechia: "Czechia",
          DRCongo: "DR Congo",
          Spain: "Spain",
          Afghanistan: "Afghanistan",
          Angola: "Angola",
          Botswana: "Botswana",
          BurkinaFaso: "Burkina Faso",
          Burundi: "Burundi",
          CapeVerde: "Cape Verde",
          Cameroon: "Cameroon",
          CentralAfricanRepublic: "Central African Republic",
          Chad: "Chad",
          Comoros: "Comoros",
          CongoRepublic: "Republic of the Congo",
          CoteDIvoire: "Côte d'Ivoire",
          Djibouti: "Djibouti",
          EquatorialGuinea: "Equatorial Guinea",
          Eritrea: "Eritrea",
          Eswatini: "Eswatini",
          Ethiopia: "Ethiopia",
          Gabon: "Gabon",
          Gambia: "Gambia",
          Ghana: "Ghana",
          Guinea: "Guinea",
          GuineaBissau: "Guinea-Bissau",
          Lesotho: "Lesotho",
          Liberia: "Liberia",
          Madagascar: "Madagascar",
          Malawi: "Malawi",
          Mali: "Mali",
          Mauritania: "Mauritania",
          Mauritius: "Mauritius",
          Mozambique: "Mozambique",
          Namibia: "Namibia",
          Niger: "Niger",
          Rwanda: "Rwanda",
          SaoTomeAndPrincipe: "São Tomé and Príncipe",
          Senegal: "Senegal",
          Seychelles: "Seychelles",
          SierraLeone: "Sierra Leone",
          Somalia: "Somalia",
          SouthSudan: "South Sudan",
          Sudan: "Sudan",
          Tanzania: "Tanzania",
          Tunisia: "Tunisia",
          Uganda: "Uganda",
          Zimbabwe: "Zimbabwe",
          Bhutan: "Bhutan",
          Iran: "Iran",
          Iraq: "Iraq",
          Jordan: "Jordan",
          Kuwait: "Kuwait",
          Kyrgyzstan: "Kyrgyzstan",
          Laos: "Laos",
          Lebanon: "Lebanon",
          Maldives: "Maldives",
          Mongolia: "Mongolia",
          Myanmar: "Myanmar",
          NorthKorea: "North Korea",
          Oman: "Oman",
          Palestine: "Palestine",
          Syria: "Syria",
          Tajikistan: "Tajikistan",
          Turkmenistan: "Turkmenistan",
          Uzbekistan: "Uzbekistan",
          Yemen: "Yemen",
          Belarus: "Belarus",
          Kosovo: "Kosovo",
          SanMarino: "San Marino",
          AntiguaAndBarbuda: "Antigua and Barbuda",
          Barbados: "Barbados",
          Belize: "Belize",
          Bolivia: "Bolivia",
          Cuba: "Cuba",
          Dominica: "Dominica",
          DominicanRepublic: "Dominican Republic",
          ElSalvador: "El Salvador",
          Grenada: "Grenada",
          Guyana: "Guyana",
          Haiti: "Haiti",
          Honduras: "Honduras",
          Nicaragua: "Nicaragua",
          Paraguay: "Paraguay",
          SaintKittsAndNevis: "Saint Kitts and Nevis",
          SaintLucia: "Saint Lucia",
          SaintVincentAndTheGrenadines: "Saint Vincent and the Grenadines",
          Suriname: "Suriname",
          TrinidadAndTobago: "Trinidad and Tobago",
          youListenedTo: "You listened to",
          from: "from", // context: from which artist an album is
          by: "by", // context: the artist an album is authored by
          youWereCharged: "You were charged", // context: a financial transaction
          youVisited: "You visited", // context: visiting a website
          youHadA: "You had a", // context: you had a calendar event
          minute: "minute",
          hour: "hour",
          calendarEvent: "calendar event",
          youReceivedAnEmailFrom: "You received an email from",
          youSentAnEmailTo: "You sent an email to",
          youReadAnEmailFrom: "You read an email from",
          youReceivedNewDataFromOura: "You received new data from Oura",
        }
      },
      es: {
        translation: {
          continueWithGoogle: "Continuar con Google",
          continueWithEmail: "Continuar con Correo Electrónico",
          byContinuingYouAgreeToThe: "Al continuar, aceptas los",
          termsOfService: "Términos de Servicio",
          and: "y",
          privacyPolicy: "Política de Privacidad",
          emailPlaceholder: "tu@correo.com",
          goBack: "Volver",
          thatDoesntMatchWhatWeSent: "Eso no coincide con lo que enviamos",
          weJustSentACodeTo: "Acabamos de enviar un código a",
          enterItHereToContinue: "Ingresa aquí para continuar",
          wrongEmail: "Correo incorrecto",
          confirm: "Confirmar",
          welcomeToVALT: "Bienvenido a VALT",
          welcomeToVALTMarketplace: "Bienvenido al Mercado VALT",
          marketplaceDataKeyDisclosure: "Aquí está tu Clave de Datos. Te permite acceder a los datos que compras, así que cópiala y pégala en algún lugar antes de continuar y nunca la compartas. Esto solo aparece en el registro. Si la pierdes, los datos comprados con esta cuenta serán irrecuperables",
          dataKeyDisclosure: "Aquí está tu Clave de Datos. Puede que la necesites para vincular dispositivos y recuperar datos, así que cópiala y pégala en algún lugar antes de continuar y nunca la compartas. Esto solo aparece en el registro. Si la pierdes, los datos de esta cuenta serán irrecuperables",
          copy: "Copiar",
          copied: "Copiado",
          ok: "Aceptar",
          withThisAppYoullBeAbleTo: "Con esta aplicación, podrás",
          secure: "Asegurar",
          interactWith: "Interactuar con",
          monetize: "Monetizar",
          yourData: "tus datos",
          howVALTSecuresData: "VALT te permite capturar los datos que creas en tus aplicaciones y servicios, como datos GPS, correo electrónico, historial de navegación, datos de compra y mucho más",
          howVALTAllowsInteraction: "Nuestro asistente de IA y la línea de tiempo de tus datos personales te permiten consultar los datos que has creado y obtener información sobre ti mismo, información que antes solo estaba disponible para Google y expertos en marketing digital",
          howVALTMonetizesData: "Con suficientes usuarios, nuestro Mercado de Datos Personales pronto te permitirá vender tus datos por primera vez. Recomienda a tus amigos y ayuda a iniciar el movimiento hoy",
          next: "Siguiente",
          back: "Atrás",
          connectdVPN: "Conectar dVPN",
          connectdVPNInstructions: "Conéctate al dVPN desde la barra de navegación en la parte inferior de la pantalla",
          connectDataSources: "Conectar Fuentes de Datos",
          connectDataSourcesInstructions: "Agrega nuevas fuentes de datos accediendo al menú de configuración en la esquina superior izquierda",
          gotIt: "Entendido",
          noDataCollectedFor: "No se han recopilado datos para",
          tapForFastestConnections: "Toca para obtener las conexiones más rápidas",
          gettingData: "Obteniendo datos",
          secureMoreDataSources: "Asegura más fuentes de datos",
          upgradeVALT: "Actualiza a VALT+",
          changeDate: "Cambiar Fecha",
          account: "Cuenta",
          connectedApps: "Aplicaciones conectadas",
          supportAndFAQ: "Soporte y Preguntas Frecuentes",
          logOut: "Cerrar sesión",
          copywright: "© 2025 HashCash, Inc",
          EULA: "EULA",
          timeline: "Cronología",
          chat: "Chat",
          moreInfo: "Más información",
          download: "Descargar",
          upload: "Subir",
          youAreCurrentlyConnectedTo: "Actualmente estás conectado a",
          updateConnection: "Actualizar conexión",
          hey: "Hola",
          welcomeBack: "Bienvenido de nuevo",
          iveDetected: "He detectado",
          leaksAssociatedWithYourData: "fugas asociadas a tus datos",
          youreNotConnectedToOurdVPNHeresAFastConnectionForYouIn: "No estás conectado a nuestro dVPN. Aquí tienes una conexión rápida para ti en",
          yourAccount: "Tu Cuenta",
          firstName: "Nombre",
          lastName: "Apellido",
          email: "Email",
          phone: "Teléfono",
          notProvided: "No Proporcionado",
          billing: "Facturación",
          deleteAccount: "Eliminar Cuenta",
          areYouSure: "¿Estás seguro?",
          yes: "Sí",
          waitNevermind: "Espera, olvídalo",
          version: "Versión",
          takeControlOfYourPersonalDataTodayWithVALTPlus: "Toma el control de tus datos personales hoy con VALT+",
          annualPlan: "Plan anual",
          currentPlan: "Plan actual",
          whatsIncluded: "Lo que incluye",
          decentralizedVPNForSecureBrowsing: "VPN descentralizado para navegación segura",
          dVPNForUnlimitedDevices: "dVPN para dispositivos ilimitados",
          advancedBackupTools: "Herramientas avanzadas de respaldo",
          fullPersonalDataTimelineIntegratedWithDozensOfApps: "Cronología completa de datos personales integrada con decenas de aplicaciones",
          dataCleanup: "Limpieza continua de datos",
          VALTWillForceBrokers: "VALT obligará a los intermediarios de datos a eliminar tu información personal para reducir llamadas y correos no deseados",
          monthlyPlan: "Plan mensual",
          twoYearPlan: "Plan de 2 años",
          billedEveryTwoYears: "Facturado cada dos años",
          billedAnnually: "Facturado anualmente",
          select: "Seleccionar",
          selectPaymentMethod: "Selecciona un método de pago",
          creditOrDebitCard: "Tarjeta de crédito o débito",
          introPriceDisclosure: "El precio introductorio es válido para el primer período de tu suscripción. Los ahorros otorgados se comparan con el precio de renovación actual",
          planUpdated: "Plan actualizado",
          somethingWentWrong: "Algo salió mal. Por favor, inténtalo de nuevo más tarde",
          updatePlan: "Actualizar plan",
          welcomeToVALTPlus: "Bienvenido a VALT+",
          weNeedToCollectDetailsToCleanupData: "Necesitamos recopilar algunos detalles para comenzar a limpiar tus datos",
          pleaseEnterYourFirstName: "Por favor, ingresa tu nombre",
          pleaseEnterYourLastName: "Por favor, ingresa tu apellido",
          pleaseEnterYourPostalCode: "Si estás en EE.UU., ingresa tu código postal; de lo contrario, ingresa 0",
          pleaseEnterYourBirthday: "Por favor, ingresa tu fecha de nacimiento",
          pleaseEnterYourPhoneNumber: "Por favor, ingresa tu número de teléfono",
          aboutVALTPlus: "Acerca de VALT+",
          aboutVALTPlusDetails: "VALT ofrece un conjunto de herramientas para ayudarte a gestionar tus datos. Al suscribirte hoy, nos ayudas a construir nuestro Mercado de Datos Personales, donde podrás recibir pago por tus datos. VALT nunca tiene acceso a los datos que recopilas en la aplicación VALT.",
          planSummary: "Resumen del plan",
          unitedStates: "Estados Unidos",
          salesTax: "Impuesto a las ventas",
          total: "Total",
          taxCountry: "País de impuestos",
          oneyearplan: "Plan de 1 año",
          twoyearplan: "Plan de 2 años",
          monthlyplan: "Plan mensual",
          unlimiteddVPNAccess: "Acceso ilimitado a dVPN",
          fullPersonalDataTimeline: "Cronología completa de datos personales",
          constantDataCleanup: "Limpieza de datos 24/7",
          serverIsUnavailable: "Servidor no disponible",
          tapToConnect: "Toca para conectar",
          connecting: "Conectando",
          tapToDisconnect: "Toca para desconectar",
          connected: "Conectado",
          returnToCurrentConnection: "Volver a la conexión actual",
          disconnecting: "Desconectando",
          connectAccounts: "Conectar cuentas",
          connectAccountsDisclosure: "Conecta tus cuentas para asegurar tus datos. Al continuar, aceptas la política de privacidad y los términos de servicio.",
          thisDataSourceIsOnlyAvailableOnMobileDevices: "Esta fuente de datos solo está disponible en dispositivos móviles",
          show: "Mostrar",
          bank: "Banco",
          onDashboard: "en el tablero",
          reauthorize: "Reautorizar",
          disconnect: "Desconectar",
          downloadTheCromeExtensionFrom: "Descarga la extensión de Chrome en tu computadora desde",
          andEnterTheFollowingCode: "e ingresa el siguiente código",
          linkDevice: "Vincular dispositivo",
          toAccessYourDataFromThisDevice: "Para acceder a tus datos desde este dispositivo, vincula tu cuenta con tu Clave de Datos",
          linkAccount: "Vincular cuenta",
          lostYourDataKey: "¿Perdiste tu Clave de Datos?",
          getNewKey: "Obtener nueva clave",
          signOut: "Cerrar sesión",
          accessYourDataOnDesktop: "Para acceder a tus datos en el escritorio, vincula tu cuenta con tu Clave de Datos",
          dataKey: "Clave de Datos",
          enterYourDataKey: "Ingresa tu Clave de Datos",
          paste: "Pegar",
          cancel: "Cancelar",
          continue: "Continuar",
          confirmDevice: "Confirmar dispositivo",
          weJustSentYouAnEmailToConfirmThisDevice: "Acabamos de enviarte un correo para confirmar este dispositivo",
          afterRespondingToEmail: "Después de responder al correo, cierra y vuelve a abrir la aplicación para obtener acceso",
          checkingForAccess: "Verificando acceso",
          wrongDevice: "Dispositivo incorrecto",
          thisDeviceIsCurrentlyConnectedToAnotherVALTAccount: "Este dispositivo está actualmente vinculado a otra cuenta de VALT",
          enterCode: "Ingresa el código",
          weJustEmailedAccountReset: "Acabamos de enviarte un código de restablecimiento de cuenta por correo. Ingrésalo aquí para continuar",
          accountResetDisclosure: "Ten en cuenta que después de restablecer tu cuenta, todos los datos recopilados con la Clave de Datos anterior no serán recuperables, y deberás iniciar sesión nuevamente en todos los dispositivos con la nueva Clave de Datos",
          resetAccount: "Restablecer cuenta",
          theCodeYouProvidedWasIncorrect: "El código que proporcionaste es incorrecto",
          resetDevice: "Restablecer dispositivo",
          VALTPlusDashboard: "Panel de VALT+",
          initDataCleanupMessage: "Estamos comenzando a limpiar tus datos. Vuelve pronto. Ten en cuenta que los servicios de limpieza de datos actualmente solo están disponibles en EE.UU.",
          selectDisplayAccount: "Seleccionar cuenta de visualización",
          wereSendingYouToTheLoginPageForThisApp: "Te estamos enviando a la página de inicio de sesión de esta aplicación",
          connectAppSuccess: "Confirmado. Regresa a tu aplicación VALT para que los cambios surtan efecto",
          save74: "(Ahorra 74%)",
          save66: "(Ahorra 66%)",
          save68: "(Ahorra 68%)",
          save60: "(Ahorra 60%)",
          save20: "(Ahorra 20%)",
          friend: "amigo",
          youJustArrived: "Acabas de llegar",
          youArrived: "Llegaste",
          ago: "hace",
          oneSecond: "1 segundo",
          oneMinute: "1 minuto",
          oneHour: "1 hora",
          seconds: "segundos",
          minutes: "minutos",
          hours: "horas",
          youVisitedThisLocation: "Visitaste este lugar",
          youSpent: "Pasaste",
          near: "cerca de",
          thisLocation: "este lugar",
          youTravelledFor: "Viajaste por",
          towards: "hacia",
          earth: "En algún lugar de la Tierra",
          at: "en",
          Georgia: "Georgia",
          Colombia: "Colombia",
          SriLanka: "Sri Lanka",
          UnitedArabEmirates: "Emiratos Árabes Unidos",
          Canada: "Canadá",
          UnitedStates: "Estados Unidos",
          Slovenia: "Eslovenia",
          Belgium: "Bélgica",
          Bahamas: "Bahamas",
          NorthMacedonia: "Macedonia del Norte",
          Ecuador: "Ecuador",
          Malaysia: "Malasia",
          Montenegro: "Montenegro",
          Azerbaijan: "Azerbaiyán",
          Jamaica: "Jamaica",
          Kazakhstan: "Kazajistán",
          Latvia: "Letonia",
          Argentina: "Argentina",
          Russia: "Rusia",
          TheNetherlands: "Países Bajos",
          Venezuela: "Venezuela",
          Germany: "Alemania",
          Turkey: "Turquía",
          Vietnam: "Vietnam",
          Luxembourg: "Luxemburgo",
          Thailand: "Tailandia",
          HongKong: "Hong Kong",
          Romania: "Rumania",
          Monaco: "Mónaco",
          Greece: "Grecia",
          IsleofMan: "Isla de Man",
          Pakistan: "Pakistán",
          Uruguay: "Uruguay",
          Armenia: "Armenia",
          Liechtenstein: "Liechtenstein",
          Qatar: "Catar",
          Switzerland: "Suiza",
          Egypt: "Egipto",
          Bulgaria: "Bulgaria",
          Cyprus: "Chipre",
          Lithuania: "Lituania",
          Panama: "Panamá",
          Croatia: "Croacia",
          Slovakia: "Eslovaquia",
          Sweden: "Suecia",
          Philippines: "Filipinas",
          Macao: "Macao",
          Austria: "Austria",
          Portugal: "Portugal",
          Algeria: "Argelia",
          Australia: "Australia",
          SouthAfrica: "Sudáfrica",
          NewZealand: "Nueva Zelanda",
          SaudiArabia: "Arabia Saudita",
          Bahrain: "Baréin",
          China: "China",
          Estonia: "Estonia",
          Morocco: "Marruecos",
          Cambodia: "Camboya",
          SouthKorea: "Corea del Sur",
          Singapore: "Singapur",
          Ireland: "Irlanda",
          Iceland: "Islandia",
          Türkiye: "Turquía",
          Guatemala: "Guatemala",
          PuertoRico: "Puerto Rico",
          Finland: "Finlandia",
          Greenland: "Groenlandia",
          Indonesia: "Indonesia",
          UnitedKingdom: "Reino Unido",
          Serbia: "Serbia",
          Nigeria: "Nigeria",
          BosniaandHerzegovina: "Bosnia y Herzegovina",
          Poland: "Polonia",
          Netherlands: "Países Bajos",
          Italy: "Italia",
          France: "Francia",
          Denmark: "Dinamarca",
          Israel: "Israel",
          Mexico: "México",
          Norway: "Noruega",
          Chile: "Chile",
          Malta: "Malta",
          Ukraine: "Ucrania",
          Brazil: "Brasil",
          Hungary: "Hungría",
          Japan: "Japón",
          Libya: "Libia",
          Nepal: "Nepal",
          Andorra: "Andorra",
          Moldova: "Moldavia",
          Kenya: "Kenia",
          Albania: "Albania",
          India: "India",
          Taiwan: "Taiwán",
          Peru: "Perú",
          Zambia: "Zambia",
          Bangladesh: "Bangladesh",
          Czechia: "Chequia",
          DRCongo: "República Democrática del Congo",
          Spain: "España",
          Afghanistan: "Afganistán",
          Angola: "Angola",
          Botswana: "Botsuana",
          BurkinaFaso: "Burkina Faso",
          Burundi: "Burundi",
          CapeVerde: "Cabo Verde",
          Cameroon: "Camerún",
          CentralAfricanRepublic: "República Centroafricana",
          Chad: "Chad",
          Comoros: "Comoras",
          CongoRepublic: "República del Congo",
          CoteDIvoire: "Costa de Marfil",
          Djibouti: "Yibuti",
          EquatorialGuinea: "Guinea Ecuatorial",
          Eritrea: "Eritrea",
          Eswatini: "Esuatini",
          Ethiopia: "Etiopía",
          Gabon: "Gabón",
          Gambia: "Gambia",
          Ghana: "Ghana",
          Guinea: "Guinea",
          GuineaBissau: "Guinea-Bisáu",
          Lesotho: "Lesoto",
          Liberia: "Liberia",
          Madagascar: "Madagascar",
          Malawi: "Malaui",
          Mali: "Malí",
          Mauritania: "Mauritania",
          Mauritius: "Mauricio",
          Mozambique: "Mozambique",
          Namibia: "Namibia",
          Niger: "Níger",
          Rwanda: "Ruanda",
          SaoTomeAndPrincipe: "Santo Tomé y Príncipe",
          Senegal: "Senegal",
          Seychelles: "Seychelles",
          SierraLeone: "Sierra Leona",
          Somalia: "Somalia",
          SouthSudan: "Sudán del Sur",
          Sudan: "Sudán",
          Tanzania: "Tanzania",
          Tunisia: "Túnez",
          Uganda: "Uganda",
          Zimbabwe: "Zimbabue",
          Bhutan: "Bután",
          Iran: "Irán",
          Iraq: "Irak",
          Jordan: "Jordania",
          Kuwait: "Kuwait",
          Kyrgyzstan: "Kirguistán",
          Laos: "Laos",
          Lebanon: "Líbano",
          Maldives: "Maldivas",
          Mongolia: "Mongolia",
          Myanmar: "Myanmar",
          NorthKorea: "Corea del Norte",
          Oman: "Omán",
          Palestine: "Palestina",
          Syria: "Siria",
          Tajikistan: "Tayikistán",
          Turkmenistan: "Turkmenistán",
          Uzbekistan: "Uzbekistán",
          Yemen: "Yemen",
          Belarus: "Bielorrusia",
          Kosovo: "Kosovo",
          SanMarino: "San Marino",
          AntiguaAndBarbuda: "Antigua y Barbuda",
          Barbados: "Barbados",
          Belize: "Belice",
          Bolivia: "Bolivia",
          Cuba: "Cuba",
          Dominica: "Dominica",
          DominicanRepublic: "República Dominicana",
          ElSalvador: "El Salvador",
          Grenada: "Granada",
          Guyana: "Guyana",
          Haiti: "Haití",
          Honduras: "Honduras",
          Nicaragua: "Nicaragua",
          Paraguay: "Paraguay",
          SaintKittsAndNevis: "San Cristóbal y Nieves",
          SaintLucia: "Santa Lucía",
          SaintVincentAndTheGrenadines: "San Vicente y las Granadinas",
          Suriname: "Surinam",
          TrinidadAndTobago: "Trinidad y Tobago",
          youListenedTo: "Escuchaste",
          from: "de",
          by: "por",
          youWereCharged: "Se te cobró",
          youVisited: "Visitaste",
          youHadA: "Tuviste un",
          minute: "minuto",
          hour: "hora",
          calendarEvent: "evento de calendario",
          youReceivedAnEmailFrom: "Recibiste un email de",
          youSentAnEmailTo: "Enviaste un email a",
          youReadAnEmailFrom: "Leíste un email de",
          youReceivedNewDataFromOura: "Has recibido nuevos datos de Oura",
        }
      },      
      fa: {
        translation: {
          continueWithGoogle: "ادامه با گوگل",
          continueWithEmail: "ادامه با ایمیل",
          byContinuingYouAgreeToThe: "با ادامه دادن، شما موافقت خود را با",
          termsOfService: "شرایط استفاده",
          and: "و",
          privacyPolicy: "سیاست حفظ حریم خصوصی",
          emailPlaceholder: "ایمیل@مثال.com",
          goBack: "بازگشت",
          thatDoesntMatchWhatWeSent: "این با آنچه ارسال کردیم مطابقت ندارد",
          weJustSentACodeTo: "ما به تازگی یک کد به",
          enterItHereToContinue: "برای ادامه، آن را اینجا وارد کنید",
          wrongEmail: "ایمیل نادرست",
          confirm: "تأیید",
          welcomeToVALT: "به VALT خوش آمدید",
          welcomeToVALTMarketplace: "به بازار VALT خوش آمدید",
          marketplaceDataKeyDisclosure: 'در اینجا کلید داده‌های شما قرار دارد. این کلید به شما امکان دسترسی به داده‌های خریداری شده را می‌دهد، بنابراین قبل از ادامه، آن را کپی/پیست کنید و هرگز به اشتراک نگذارید. این تنها در زمان ثبت‌نام نمایش داده می‌شود. اگر از دست رفت، داده‌های خریداری شده توسط این حساب قابل بازیابی نخواهند بود',
          dataKeyDisclosure: 'در اینجا کلید داده‌های شما قرار دارد. ممکن است برای اتصال دستگاه‌ها و بازیابی داده‌ها به آن نیاز داشته باشید، بنابراین قبل از ادامه، آن را کپی/پیست کنید و هرگز به اشتراک نگذارید. این تنها در زمان ثبت‌نام نمایش داده می‌شود. اگر از دست رفت، داده‌های این حساب قابل بازیابی نخواهند بود',
          copy: "کپی",
          copied: "کپی شد",
          ok: "تأیید",
          withThisAppYoullBeAbleTo: "با این برنامه، قادر خواهید بود",
          secure: "امن",
          interactWith: "تعامل با",
          monetize: "درآمدزایی",
          yourData: "داده‌های شما",
          howVALTSecuresData: "VALT به شما امکان می‌دهد داده‌هایی را که در برنامه‌ها و سرویس‌های خود ایجاد می‌کنید ضبط کنید، مانند داده‌های GPS، ایمیل، تاریخچه مرور، داده‌های خرید و بسیاری موارد دیگر",
          howVALTAllowsInteraction: "دستیار هوش مصنوعی ما و خط زمانی داده‌های شخصی به شما امکان می‌دهند به داده‌هایی که ایجاد کرده‌اید مراجعه کنید و بینش‌هایی درباره خودتان به دست آورید که قبلاً تنها برای گوگل و بازاریابان دیجیتال قابل دسترسی بود",
          howVALTMonetizesData: "با داشتن کاربران کافی، بازار داده‌های شخصی ما به زودی به شما امکان می‌دهد برای اولین بار داده‌های خود را به فروش برسانید. دوستان خود را معرفی کنید و به شروع این حرکت کمک کنید",
          next: "بعدی",
          back: "بازگشت",
          connectdVPN: "اتصال به dVPN",
          connectdVPNInstructions: "از نوار ناوبری پایین صفحه به dVPN متصل شوید",
          connectDataSources: "اتصال به منابع داده",
          connectDataSourcesInstructions: "با دسترسی به منوی تنظیمات واقع در گوشه بالا سمت چپ، منابع داده جدید اضافه کنید",
          gotIt: "متوجه شدم",
          noDataCollectedFor: "هیچ داده‌ای جمع‌آوری نشده برای",
          tapForFastestConnections: "برای سریع‌ترین ارتباط‌ها ضربه بزنید",
          gettingData: "در حال دریافت داده",
          secureMoreDataSources: "منابع داده ایمن بیشتری متصل کنید",
          upgradeVALT: "ارتقا به VALT+",
          changeDate: "تغییر تاریخ",
          account: "حساب",
          connectedApps: "برنامه‌های متصل",
          supportAndFAQ: "پشتیبانی و پرسش‌های متداول",
          logOut: "خروج",
          copywright: "© 2025 HashCash, Inc",
          EULA: "توافق‌نامه کاربری",
          timeline: "جدول زمانی",
          chat: "چت",
          moreInfo: "اطلاعات بیشتر",
          download: "دانلود",
          upload: "بارگذاری",
          youAreCurrentlyConnectedTo: "شما در حال حاضر به",
          updateConnection: "به‌روزرسانی اتصال",
          hey: "هی",
          welcomeBack: "خوش آمدید",
          iveDetected: "من تشخیص داده‌ام",
          leaksAssociatedWithYourData: "نشت‌های مرتبط با داده‌های شما",
          youreNotConnectedToOurdVPNHeresAFastConnectionForYouIn: "شما به dVPN ما متصل نیستید. در اینجا یک ارتباط سریع برای شما در",
          yourAccount: "حساب شما",
          firstName: "نام",
          lastName: "نام خانوادگی",
          email: "ایمیل",
          phone: "تلفن",
          notProvided: "ارائه نشده",
          billing: "صورتحساب",
          deleteAccount: "حذف حساب",
          areYouSure: "آیا مطمئن هستید؟",
          yes: "بله",
          waitNevermind: "صبر کنید، منصرف شدم",
          version: "نسخه",
          takeControlOfYourPersonalDataTodayWithVALTPlus: "امروز کنترل داده‌های شخصی خود را با VALT+ در دست بگیرید",
          annualPlan: "طرح سالانه",
          currentPlan: "طرح فعلی",
          whatsIncluded: "آنچه شامل می‌شود",
          decentralizedVPNForSecureBrowsing: "VPN غیرمتمرکز برای مرور امن",
          dVPNForUnlimitedDevices: "dVPN برای دستگاه‌های نامحدود",
          advancedBackupTools: "ابزارهای پشتیبان‌گیری پیشرفته",
          fullPersonalDataTimelineIntegratedWithDozensOfApps: "خط زمانی جامع داده‌های شخصی، یکپارچه با ده‌ها برنامه",
          dataCleanup: "پاکسازی مداوم داده‌ها",
          VALTWillForceBrokers: "VALT بروکرهای داده را ملزم می‌کند که اطلاعات شخصی شما را حذف کنند تا تماس‌ها و ایمیل‌های ناخواسته کاهش یابد",
          monthlyPlan: "طرح ماهانه",
          twoYearPlan: "طرح دو ساله",
          billedEveryTwoYears: "هر دو سال یکبار صورتحساب",
          billedAnnually: "هر سال صورتحساب",
          select: "انتخاب",
          selectPaymentMethod: "انتخاب روش پرداخت",
          creditOrDebitCard: "کارت اعتباری یا بدهی",
          introPriceDisclosure: "قیمت اولیه معرفی شده برای اولین دوره اشتراک شما معتبر است. صرفه‌جویی‌های ارائه شده با قیمت اولیه نسبت به قیمت تجدید فعلی مقایسه می‌شود",
          planUpdated: "طرح به‌روزرسانی شد",
          somethingWentWrong: "اشکالی پیش آمد. لطفاً بعداً دوباره تلاش کنید",
          updatePlan: "به‌روزرسانی طرح",
          welcomeToVALTPlus: "به VALT+ خوش آمدید",
          weNeedToCollectDetailsToCleanupData: "برای شروع پاکسازی داده‌های شما، نیاز به جمع‌آوری چند جزئیات داریم",
          pleaseEnterYourFirstName: "لطفاً نام خود را وارد کنید",
          pleaseEnterYourLastName: "لطفاً نام خانوادگی خود را وارد کنید",
          pleaseEnterYourPostalCode: "اگر در آمریکا هستید، لطفاً کد پستی خود را وارد کنید - در غیر این صورت 0 را وارد کنید",
          pleaseEnterYourBirthday: "لطفاً تاریخ تولد خود را وارد کنید",
          pleaseEnterYourPhoneNumber: "لطفاً شماره تلفن خود را وارد کنید",
          aboutVALTPlus: "درباره VALT+",
          aboutVALTPlusDetails: "VALT مجموعه‌ای از ابزارها را برای کمک به مدیریت داده‌های شما ارائه می‌دهد. با اشتراک امروز، در ساخت بازار داده‌های شخصی ما کمک می‌کنید که در آن می‌توانید بابت داده‌های خود دستمزد بگیرید. VALT هرگز به داده‌هایی که در اپلیکیشن VALT جمع‌آوری می‌کنید دسترسی ندارد.",
          planSummary: "خلاصه طرح",
          unitedStates: "ایالات متحده",
          salesTax: "مالیات فروش",
          total: "کل",
          taxCountry: "کشور مالیاتی",
          oneyearplan: "طرح یک ساله",
          twoyearplan: "طرح دو ساله",
          monthlyplan: "طرح ماهانه",
          unlimiteddVPNAccess: "دسترسی نامحدود به dVPN",
          fullPersonalDataTimeline: "خط زمانی کامل داده‌های شخصی",
          constantDataCleanup: "پاکسازی داده ۲۴/۷",
          serverIsUnavailable: "سرور در دسترس نیست",
          tapToConnect: "برای اتصال ضربه بزنید",
          connecting: "در حال اتصال",
          tapToDisconnect: "برای قطع اتصال ضربه بزنید",
          connected: "متصل",
          returnToCurrentConnection: "بازگشت به اتصال فعلی",
          disconnecting: "در حال قطع اتصال",
          connectAccounts: "اتصال حساب‌ها",
          connectAccountsDisclosure: "حساب‌های خود را متصل کنید تا داده‌های شما ایمن باشد. با ادامه، شما با سیاست حفظ حریم خصوصی و شرایط استفاده موافقت می‌کنید.",
          thisDataSourceIsOnlyAvailableOnMobileDevices: "این منبع داده تنها بر روی دستگاه‌های موبایل در دسترس است",
          show: "نمایش",
          bank: "بانک",
          onDashboard: "در داشبورد",
          reauthorize: "تأیید مجدد",
          disconnect: "قطع اتصال",
          downloadTheCromeExtensionFrom: "افزونه کروم را روی رایانه خود از",
          andEnterTheFollowingCode: "و کد زیر را وارد کنید",
          linkDevice: "اتصال دستگاه",
          toAccessYourDataFromThisDevice: "برای دسترسی به داده‌های خود از این دستگاه، حساب خود را با کلید داده‌تان متصل کنید",
          linkAccount: "اتصال حساب",
          lostYourDataKey: "کلید داده خود را گم کرده‌اید",
          getNewKey: "دریافت کلید جدید",
          signOut: "خروج",
          accessYourDataOnDesktop: "برای دسترسی به داده‌های خود در دسکتاپ، حساب خود را با کلید داده متصل کنید",
          dataKey: "کلید داده",
          enterYourDataKey: "کلید داده خود را وارد کنید",
          paste: "چسباندن",
          cancel: "لغو",
          continue: "ادامه",
          confirmDevice: "تأیید دستگاه",
          weJustSentYouAnEmailToConfirmThisDevice: "ما به تازگی یک ایمیل برای تأیید این دستگاه ارسال کردیم",
          afterRespondingToEmail: "پس از پاسخ به ایمیل، اپ را ببندید و دوباره باز کنید تا به آن دسترسی پیدا کنید",
          checkingForAccess: "در حال بررسی دسترسی",
          wrongDevice: "دستگاه اشتباه",
          thisDeviceIsCurrentlyConnectedToAnotherVALTAccount: "این دستگاه در حال حاضر به حساب VALT دیگری متصل است",
          enterCode: "کد را وارد کنید",
          weJustEmailedAccountReset: "ما به تازگی یک کد بازنشانی حساب برای شما ایمیل کردیم. آن را اینجا وارد کنید تا ادامه دهید",
          accountResetDisclosure: "لطفاً توجه داشته باشید که پس از بازنشانی حساب، تمامی داده‌های جمع‌آوری‌شده با کلید داده قبلی قابل بازیابی نخواهد بود و شما باید با کلید داده جدید، دوباره در تمامی دستگاه‌ها وارد شوید",
          resetAccount: "بازنشانی حساب",
          theCodeYouProvidedWasIncorrect: "کدی که وارد کردید نادرست است",
          resetDevice: "بازنشانی دستگاه",
          VALTPlusDashboard: "داشبورد VALT+",
          initDataCleanupMessage: "ما در حال شروع به پاکسازی داده‌های شما هستیم. به زودی دوباره مراجعه کنید. لطفاً توجه داشته باشید که خدمات پاکسازی داده‌ها در حال حاضر فقط در ایالات متحده پشتیبانی می‌شود.",
          selectDisplayAccount: "انتخاب حساب نمایش",
          wereSendingYouToTheLoginPageForThisApp: "ما شما را به صفحه ورود این برنامه می‌بریم",
          connectAppSuccess: "تأیید شد. برای اعمال تغییرات به اپ VALT خود بازگردید",
          save74: "(صرفه‌جویی 74%)",
          save66: "(صرفه‌جویی 66%)",
          save68: "(صرفه‌جویی 68%)",
          save60: "(صرفه‌جویی 60%)",
          save20: "(صرفه‌جویی 20%)",
          friend: "رفیق",
          youJustArrived: "شما تازه رسیدید",
          youArrived: "شما رسیدید",
          ago: "قبل",
          oneSecond: "۱ ثانیه",
          oneMinute: "۱ دقیقه",
          oneHour: "۱ ساعت",
          seconds: "ثانیه",
          minutes: "دقیقه",
          hours: "ساعت",
          youVisitedThisLocation: "شما این مکان را بازدید کردید",
          youSpent: "شما صرف کردید",
          near: "نزدیک",
          thisLocation: "این مکان",
          youTravelledFor: "شما به مدت سفر کردید",
          towards: "به سمت",
          earth: "جایی روی زمین",
          at: "در",
          Georgia: "گرجستان",
          Colombia: "کلمبیا",
          SriLanka: "سریلانکا",
          UnitedArabEmirates: "امارات متحده عربی",
          Canada: "کانادا",
          UnitedStates: "ایالات متحده",
          Slovenia: "اسلوونی",
          Belgium: "بلژیک",
          Bahamas: "باهاما",
          NorthMacedonia: "مقدونیه شمالی",
          Ecuador: "اکوادور",
          Malaysia: "مالزی",
          Montenegro: "مونته‌نگرو",
          Azerbaijan: "آذربایجان",
          Jamaica: "جامائیکا",
          Kazakhstan: "قزاقستان",
          Latvia: "لتونی",
          Argentina: "آرژانتین",
          Russia: "روسیه",
          TheNetherlands: "هلند",
          Venezuela: "ونزوئلا",
          Germany: "آلمان",
          Turkey: "ترکیه",
          Vietnam: "ویتنام",
          Luxembourg: "لوکزامبورگ",
          Thailand: "تایلند",
          HongKong: "هنگ کنگ",
          Romania: "رومانی",
          Monaco: "موناکو",
          Greece: "یونان",
          IsleofMan: "جزیره من",
          Pakistan: "پاکستان",
          Uruguay: "اروگوئه",
          Armenia: "ارمنستان",
          Liechtenstein: "لیختن‌اشتاین",
          Qatar: "قطر",
          Switzerland: "سوئیس",
          Egypt: "مصر",
          Bulgaria: "بلغارستان",
          Cyprus: "قبرس",
          Lithuania: "لیتوانی",
          Panama: "پاناما",
          Croatia: "کرواتیا",
          Slovakia: "اسلواکی",
          Sweden: "سوئد",
          Philippines: "فیلیپین",
          Macao: "ماکائو",
          Austria: "اتریش",
          Portugal: "پرتغال",
          Algeria: "الجزایر",
          Australia: "استرالیا",
          SouthAfrica: "آفریقای جنوبی",
          NewZealand: "نیوزیلند",
          SaudiArabia: "عربستان سعودی",
          Bahrain: "بحرین",
          China: "چین",
          Estonia: "استونی",
          Morocco: "مراکش",
          Cambodia: "کامبوج",
          SouthKorea: "کره جنوبی",
          Singapore: "سنگاپور",
          Ireland: "ایرلند",
          Iceland: "ایسلند",
          Türkiye: "ترکیه",
          Guatemala: "گواتمالا",
          PuertoRico: "پورتوریکو",
          Finland: "فنلاند",
          Greenland: "گرینلند",
          Indonesia: "اندونزی",
          UnitedKingdom: "انگلستان",
          Serbia: "صربستان",
          Nigeria: "نیجریه",
          BosniaandHerzegovina: "بوسنی و هرزگوین",
          Poland: "لهستان",
          Netherlands: "هلند",
          Italy: "ایتالیا",
          France: "فرانسه",
          Denmark: "دانمارک",
          Israel: "اسرائیل",
          Mexico: "مکزیک",
          Norway: "نروژ",
          Chile: "شیلی",
          Malta: "مالت",
          Ukraine: "اوکراین",
          Brazil: "برزیل",
          Hungary: "مجارستان",
          Japan: "ژاپن",
          Libya: "لیبی",
          Nepal: "نپال",
          Andorra: "آندورا",
          Moldova: "مولداوی",
          Kenya: "کنیا",
          Albania: "آلبانی",
          India: "هند",
          Taiwan: "تایوان",
          Peru: "پرو",
          Zambia: "زامبیا",
          Bangladesh: "بنگلادش",
          Czechia: "چک",
          DRCongo: "کنگو دموکراتیک",
          Spain: "اسپانیا",
          Afghanistan: "افغانستان",
          Angola: "آنگولا",
          Botswana: "بوتسوانا",
          BurkinaFaso: "بورکینافاسو",
          Burundi: "بوروندی",
          CapeVerde: "کیپ ورد",
          Cameroon: "کامرون",
          CentralAfricanRepublic: "جمهوری آفریقای مرکزی",
          Chad: "چاد",
          Comoros: "کومور",
          CongoRepublic: "جمهوری کنگو",
          CoteDIvoire: "ساحل عاج",
          Djibouti: "جیبوتی",
          EquatorialGuinea: "گینه استوایی",
          Eritrea: "اریتره",
          Eswatini: "اسواتینی",
          Ethiopia: "اتیوپی",
          Gabon: "گابن",
          Gambia: "گامبیا",
          Ghana: "غانا",
          Guinea: "گینه",
          GuineaBissau: "گینه بیسائو",
          Lesotho: "لسوتو",
          Liberia: "لیبریا",
          Madagascar: "ماداگاسکار",
          Malawi: "مالاوی",
          Mali: "مالی",
          Mauritania: "موریتانی",
          Mauritius: "موریس",
          Mozambique: "موزامبیک",
          Namibia: "نامیبیا",
          Niger: "نیجر",
          Rwanda: "رواندا",
          SaoTomeAndPrincipe: "سائو تومه و پرینسیپ",
          Senegal: "سنگال",
          Seychelles: "سیشل",
          SierraLeone: "سیرالئون",
          Somalia: "سومالی",
          SouthSudan: "جنوب سودان",
          Sudan: "سودان",
          Tanzania: "تانزانیا",
          Tunisia: "تونس",
          Uganda: "اوگاندا",
          Zimbabwe: "زیمبابوه",
          Bhutan: "بوتان",
          Iran: "ایران",
          Iraq: "عراق",
          Jordan: "اردن",
          Kuwait: "کویت",
          Kyrgyzstan: "قرقیزستان",
          Laos: "لائوس",
          Lebanon: "لبنان",
          Maldives: "مالدیو",
          Mongolia: "منگولیا",
          Myanmar: "میانمار",
          NorthKorea: "کره شمالی",
          Oman: "عمان",
          Palestine: "فلسطین",
          Syria: "سوریه",
          Tajikistan: "تاجیکستان",
          Turkmenistan: "ترکمنستان",
          Uzbekistan: "ازبکستان",
          Yemen: "یمن",
          Belarus: "بلاروس",
          Kosovo: "کوزوو",
          SanMarino: "سان مارینو",
          AntiguaAndBarbuda: "آنتیگوا و باربودا",
          Barbados: "باربادوس",
          Belize: "بلیز",
          Bolivia: "بولیوی",
          Cuba: "کوبا",
          Dominica: "دومینیکا",
          DominicanRepublic: "جمهوری دومینیکن",
          ElSalvador: "السلفادور",
          Grenada: "گرنادا",
          Guyana: "گویانا",
          Haiti: "هایتی",
          Honduras: "هندوراس",
          Nicaragua: "نیکاراگوا",
          Paraguay: "پاراگوئه",
          SaintKittsAndNevis: "سنت کیتس و نویس",
          SaintLucia: "سنت لوسیا",
          SaintVincentAndTheGrenadines: "سنت وینسنت و گرنادین‌ها",
          Suriname: "سورینام",
          TrinidadAndTobago: "ترینیداد و توباگو",
          youListenedTo: "شما گوش دادید",
          from: "از",
          by: "توسط",
          youWereCharged: "از شما هزینه‌ای گرفته شد",
          youVisited: "شما بازدید کردید",
          youHadA: "شما یک",
          minute: "دقیقه",
          hour: "ساعت",
          calendarEvent: "رویداد تقویمی",
          youReceivedAnEmailFrom: "شما ایمیلی از",
          youSentAnEmailTo: "شما ایمیلی به",
          youReadAnEmailFrom: "شما ایمیلی از",
          youReceivedNewDataFromOura: "شما داده‌های جدیدی از Oura دریافت کردید",
        }
      },
      zh: {
        translation: {
          continueWithGoogle: "使用 Google 继续",
          continueWithEmail: "使用邮箱继续",
          byContinuingYouAgreeToThe: "继续即表示您同意",
          termsOfService: "服务条款",
          and: "和",
          privacyPolicy: "隐私政策",
          emailPlaceholder: "你的邮箱@example.com",
          goBack: "返回",
          thatDoesntMatchWhatWeSent: "与我们发送的不匹配",
          weJustSentACodeTo: "我们刚刚发送了验证码至",
          enterItHereToContinue: "请输入验证码以继续",
          wrongEmail: "邮箱错误",
          confirm: "确认",
          welcomeToVALT: "欢迎来到 VALT",
          welcomeToVALTMarketplace: "欢迎来到 VALT 市场",
          marketplaceDataKeyDisclosure: "这是您的数据密钥。它可让您访问购买的数据，请在继续前复制并保存此密钥，且切勿分享。此信息仅在注册时显示，如果丢失，您购买的数据将无法恢复",
          dataKeyDisclosure: "这是您的数据密钥。您可能需要此密钥来链接设备和恢复数据，请在继续前复制并保存此密钥，且切勿分享。此信息仅在注册时显示，如果丢失，该账户的数据将无法恢复",
          copy: "复制",
          copied: "已复制",
          ok: "确定",
          withThisAppYoullBeAbleTo: "通过此应用，您可以",
          secure: "保护",
          interactWith: "交互",
          monetize: "变现",
          yourData: "您的数据",
          howVALTSecuresData: "VALT 允许您捕捉在应用和服务中创建的数据，如 GPS 数据、电子邮件、浏览历史、购买记录等",
          howVALTAllowsInteraction: "我们的 AI 助手和个人数据时间线可帮助您回顾已创建的数据，并获取之前只有谷歌和数字营销人员才能获得的洞察",
          howVALTMonetizesData: "随着用户数量增加，我们的个人数据市场将很快允许您首次出售您的数据。邀请您的朋友，共同启动这一运动",
          next: "下一步",
          back: "返回",
          connectdVPN: "连接 dVPN",
          connectdVPNInstructions: "从屏幕底部的导航栏连接 dVPN",
          connectDataSources: "连接数据源",
          connectDataSourcesInstructions: "通过左上角的设置菜单添加新数据源",
          gotIt: "明白了",
          noDataCollectedFor: "未收集数据",
          tapForFastestConnections: "点击获取最快连接",
          gettingData: "获取数据中",
          secureMoreDataSources: "连接更多安全数据源",
          upgradeVALT: "升级至 VALT+",
          changeDate: "更改日期",
          account: "账户",
          connectedApps: "已连接应用",
          supportAndFAQ: "支持与常见问题",
          logOut: "登出",
          copywright: "© 2025 HashCash, Inc",
          EULA: "最终用户许可协议",
          timeline: "时间线",
          chat: "聊天",
          moreInfo: "更多信息",
          download: "下载",
          upload: "上传",
          youAreCurrentlyConnectedTo: "您当前连接到",
          updateConnection: "更新连接",
          hey: "嘿",
          welcomeBack: "欢迎回来",
          iveDetected: "我发现了",
          leaksAssociatedWithYourData: "与您的数据相关的泄露",
          youreNotConnectedToOurdVPNHeresAFastConnectionForYouIn: "您尚未连接到我们的 dVPN。请尝试以下快速连接线路",
          yourAccount: "您的账户",
          firstName: "名字",
          lastName: "姓氏",
          email: "邮箱",
          phone: "电话",
          notProvided: "未提供",
          billing: "账单",
          deleteAccount: "删除账户",
          areYouSure: "您确定吗？",
          yes: "是",
          waitNevermind: "等等，不用了",
          version: "版本",
          takeControlOfYourPersonalDataTodayWithVALTPlus: "今天就用 VALT+掌控您的个人数据",
          annualPlan: "年计划",
          currentPlan: "当前计划",
          whatsIncluded: "包含内容",
          decentralizedVPNForSecureBrowsing: "去中心化 VPN 保障安全浏览",
          dVPNForUnlimitedDevices: "dVPN 支持无限设备",
          advancedBackupTools: "高级备份工具",
          fullPersonalDataTimelineIntegratedWithDozensOfApps: "整合多款应用的完整个人数据时间线",
          dataCleanup: "持续数据清理",
          VALTWillForceBrokers: "VALT 将迫使数据经纪人删除您的个人信息，以减少垃圾电话和邮件",
          monthlyPlan: "月计划",
          twoYearPlan: "两年计划",
          billedEveryTwoYears: "每两年计费",
          billedAnnually: "每年计费",
          select: "选择",
          selectPaymentMethod: "选择支付方式",
          creditOrDebitCard: "信用卡或借记卡",
          introPriceDisclosure: "首期优惠价适用于您的第一期订阅。优惠金额与当前续订价格对比得出",
          planUpdated: "计划已更新",
          somethingWentWrong: "出了点问题，请稍后再试",
          updatePlan: "更新计划",
          welcomeToVALTPlus: "欢迎使用 VALT+",
          weNeedToCollectDetailsToCleanupData: "我们需要收集一些信息以开始清理您的数据",
          pleaseEnterYourFirstName: "请输入您的名字",
          pleaseEnterYourLastName: "请输入您的姓氏",
          pleaseEnterYourPostalCode: "如果您在美国，请输入邮编，否则输入 0",
          pleaseEnterYourBirthday: "请输入您的生日",
          pleaseEnterYourPhoneNumber: "请输入您的电话号码",
          aboutVALTPlus: "关于 VALT+",
          aboutVALTPlusDetails: "VALT 提供一系列工具帮助您管理数据。通过今日订阅，您将帮助我们构建个人数据市场，从而使您获得数据收益。VALT 永远不会访问您在 VALT 应用中收集的数据。",
          planSummary: "计划摘要",
          unitedStates: "美国",
          salesTax: "销售税",
          total: "总计",
          taxCountry: "税收国家",
          oneyearplan: "1 年计划",
          twoyearplan: "2 年计划",
          monthlyplan: "月计划",
          unlimiteddVPNAccess: "无限 dVPN 访问",
          fullPersonalDataTimeline: "完整个人数据时间线",
          constantDataCleanup: "全天候数据清理",
          serverIsUnavailable: "服务器不可用",
          tapToConnect: "点击连接",
          connecting: "连接中",
          tapToDisconnect: "点击断开",
          connected: "已连接",
          returnToCurrentConnection: "返回当前连接",
          disconnecting: "断开中",
          connectAccounts: "连接账户",
          connectAccountsDisclosure: "连接您的账户以保护数据。继续即表示您同意隐私政策和服务条款。",
          thisDataSourceIsOnlyAvailableOnMobileDevices: "此数据源仅在移动设备上可用",
          show: "显示",
          bank: "银行",
          onDashboard: "在仪表盘上",
          reauthorize: "重新授权",
          disconnect: "断开连接",
          downloadTheCromeExtensionFrom: "在电脑上从以下网址下载 Chrome 扩展",
          andEnterTheFollowingCode: "并输入以下代码",
          linkDevice: "链接设备",
          toAccessYourDataFromThisDevice: "若要从此设备访问数据，请将账户与数据密钥链接",
          linkAccount: "链接账户",
          lostYourDataKey: "丢失数据密钥？",
          getNewKey: "获取新密钥",
          signOut: "登出",
          accessYourDataOnDesktop: "若要在桌面访问数据，请将账户与数据密钥链接",
          dataKey: "数据密钥",
          enterYourDataKey: "请输入数据密钥",
          paste: "粘贴",
          cancel: "取消",
          continue: "继续",
          confirmDevice: "确认设备",
          weJustSentYouAnEmailToConfirmThisDevice: "我们刚刚发送了一封确认设备的邮件",
          afterRespondingToEmail: "回复邮件后，请关闭并重新打开应用以获取访问权限",
          checkingForAccess: "正在检查访问权限",
          wrongDevice: "设备错误",
          thisDeviceIsCurrentlyConnectedToAnotherVALTAccount: "此设备当前已链接到另一个 VALT 账户",
          enterCode: "输入代码",
          weJustEmailedAccountReset: "我们刚刚发送了账户重置代码，请在此输入以继续",
          accountResetDisclosure: "请注意，重置账户后，之前数据密钥收集的所有数据将无法恢复，您需要使用新数据密钥重新登录所有设备",
          resetAccount: "重置账户",
          theCodeYouProvidedWasIncorrect: "您输入的代码不正确",
          resetDevice: "重置设备",
          VALTPlusDashboard: "VALT+ 仪表盘",
          initDataCleanupMessage: "我们正在开始清理您的数据，请稍后回来查看。请注意，目前数据清理服务仅在美国提供",
          selectDisplayAccount: "选择显示账户",
          wereSendingYouToTheLoginPageForThisApp: "我们正在将您转至此应用的登录页面",
          connectAppSuccess: "确认成功。请返回 VALT 应用以使更改生效",
          save74: "(节省 74%)",
          save66: "(节省 66%)",
          save68: "(节省 68%)",
          save60: "(节省 60%)",
          save20: "(节省 20%)",
          friend: "朋友",
          youJustArrived: "您刚刚到达",
          youArrived: "您已到达",
          ago: "之前",
          oneSecond: "1秒",
          oneMinute: "1分钟",
          oneHour: "1小时",
          seconds: "秒",
          minutes: "分钟",
          hours: "小时",
          youVisitedThisLocation: "您访问了此地点",
          youSpent: "您花费了",
          near: "在附近",
          thisLocation: "此地点",
          youTravelledFor: "您旅行了",
          towards: "朝",
          earth: "地球上的某处",
          at: "在",
          Georgia: "格鲁吉亚",
          Colombia: "哥伦比亚",
          SriLanka: "斯里兰卡",
          UnitedArabEmirates: "阿联酋",
          Canada: "加拿大",
          UnitedStates: "美国",
          Slovenia: "斯洛文尼亚",
          Belgium: "比利时",
          Bahamas: "巴哈马",
          NorthMacedonia: "北马其顿",
          Ecuador: "厄瓜多尔",
          Malaysia: "马来西亚",
          Montenegro: "黑山",
          Azerbaijan: "阿塞拜疆",
          Jamaica: "牙买加",
          Kazakhstan: "哈萨克斯坦",
          Latvia: "拉脱维亚",
          Argentina: "阿根廷",
          Russia: "俄罗斯",
          TheNetherlands: "荷兰",
          Venezuela: "委内瑞拉",
          Germany: "德国",
          Turkey: "土耳其",
          Vietnam: "越南",
          Luxembourg: "卢森堡",
          Thailand: "泰国",
          HongKong: "香港",
          Romania: "罗马尼亚",
          Monaco: "摩纳哥",
          Greece: "希腊",
          IsleofMan: "马恩岛",
          Pakistan: "巴基斯坦",
          Uruguay: "乌拉圭",
          Armenia: "亚美尼亚",
          Liechtenstein: "列支敦士登",
          Qatar: "卡塔尔",
          Switzerland: "瑞士",
          Egypt: "埃及",
          Bulgaria: "保加利亚",
          Cyprus: "塞浦路斯",
          Lithuania: "立陶宛",
          Panama: "巴拿马",
          Croatia: "克罗地亚",
          Slovakia: "斯洛伐克",
          Sweden: "瑞典",
          Philippines: "菲律宾",
          Macao: "澳门",
          Austria: "奥地利",
          Portugal: "葡萄牙",
          Algeria: "阿尔及利亚",
          Australia: "澳大利亚",
          SouthAfrica: "南非",
          NewZealand: "新西兰",
          SaudiArabia: "沙特阿拉伯",
          Bahrain: "巴林",
          China: "中国",
          Estonia: "爱沙尼亚",
          Morocco: "摩洛哥",
          Cambodia: "柬埔寨",
          SouthKorea: "韩国",
          Singapore: "新加坡",
          Ireland: "爱尔兰",
          Iceland: "冰岛",
          Türkiye: "土耳其",
          Guatemala: "危地马拉",
          PuertoRico: "波多黎各",
          Finland: "芬兰",
          Greenland: "格陵兰",
          Indonesia: "印度尼西亚",
          UnitedKingdom: "英国",
          Serbia: "塞尔维亚",
          Nigeria: "尼日利亚",
          BosniaandHerzegovina: "波斯尼亚和黑塞哥维那",
          Poland: "波兰",
          Netherlands: "荷兰",
          Italy: "意大利",
          France: "法国",
          Denmark: "丹麦",
          Israel: "以色列",
          Mexico: "墨西哥",
          Norway: "挪威",
          Chile: "智利",
          Malta: "马耳他",
          Ukraine: "乌克兰",
          Brazil: "巴西",
          Hungary: "匈牙利",
          Japan: "日本",
          Libya: "利比亚",
          Nepal: "尼泊尔",
          Andorra: "安道尔",
          Moldova: "摩尔多瓦",
          Kenya: "肯尼亚",
          Albania: "阿尔巴尼亚",
          India: "印度",
          Taiwan: "台湾",
          Peru: "秘鲁",
          Zambia: "赞比亚",
          Bangladesh: "孟加拉国",
          Czechia: "捷克",
          DRCongo: "刚果民主共和国",
          Spain: "西班牙",
          Afghanistan: "阿富汗",
          Angola: "安哥拉",
          Botswana: "博茨瓦纳",
          BurkinaFaso: "布基纳法索",
          Burundi: "布隆迪",
          CapeVerde: "佛得角",
          Cameroon: "喀麦隆",
          CentralAfricanRepublic: "中非共和国",
          Chad: "乍得",
          Comoros: "科摩罗",
          CongoRepublic: "刚果共和国",
          CoteDIvoire: "科特迪瓦",
          Djibouti: "吉布提",
          EquatorialGuinea: "赤道几内亚",
          Eritrea: "厄立特里亚",
          Eswatini: "斯威士兰",
          Ethiopia: "埃塞俄比亚",
          Gabon: "加蓬",
          Gambia: "冈比亚",
          Ghana: "加纳",
          Guinea: "几内亚",
          GuineaBissau: "几内亚比绍",
          Lesotho: "莱索托",
          Liberia: "利比里亚",
          Madagascar: "马达加斯加",
          Malawi: "马拉维",
          Mali: "马里",
          Mauritania: "毛里塔尼亚",
          Mauritius: "毛里求斯",
          Mozambique: "莫桑比克",
          Namibia: "纳米比亚",
          Niger: "尼日尔",
          Rwanda: "卢旺达",
          SaoTomeAndPrincipe: "圣多美和普林西比",
          Senegal: "塞内加尔",
          Seychelles: "塞舌尔",
          SierraLeone: "塞拉利昂",
          Somalia: "索马里",
          SouthSudan: "南苏丹",
          Sudan: "苏丹",
          Tanzania: "坦桑尼亚",
          Tunisia: "突尼斯",
          Uganda: "乌干达",
          Zimbabwe: "津巴布韦",
          Bhutan: "不丹",
          Iran: "伊朗",
          Iraq: "伊拉克",
          Jordan: "约旦",
          Kuwait: "科威特",
          Kyrgyzstan: "吉尔吉斯斯坦",
          Laos: "老挝",
          Lebanon: "黎巴嫩",
          Maldives: "马尔代夫",
          Mongolia: "蒙古",
          Myanmar: "缅甸",
          NorthKorea: "朝鲜",
          Oman: "阿曼",
          Palestine: "巴勒斯坦",
          Syria: "叙利亚",
          Tajikistan: "塔吉克斯坦",
          Turkmenistan: "土库曼斯坦",
          Uzbekistan: "乌兹别克斯坦",
          Yemen: "也门",
          Belarus: "白俄罗斯",
          Kosovo: "科索沃",
          SanMarino: "圣马力诺",
          AntiguaAndBarbuda: "安提瓜和巴布达",
          Barbados: "巴巴多斯",
          Belize: "伯利兹",
          Bolivia: "玻利维亚",
          Cuba: "古巴",
          Dominica: "多米尼加",
          DominicanRepublic: "多米尼加共和国",
          ElSalvador: "萨尔瓦多",
          Grenada: "格林纳达",
          Guyana: "圭亚那",
          Haiti: "海地",
          Honduras: "洪都拉斯",
          Nicaragua: "尼加拉瓜",
          Paraguay: "巴拉圭",
          SaintKittsAndNevis: "圣基茨和尼维斯",
          SaintLucia: "圣卢西亚",
          SaintVincentAndTheGrenadines: "圣文森特和格林纳丁斯",
          Suriname: "苏里南",
          TrinidadAndTobago: "特立尼达和多巴哥",
          youListenedTo: "你听了",
          from: "来自",
          by: "由",
          youWereCharged: "你被收费了",
          youVisited: "你访问了",
          youHadA: "你有一个",
          minute: "分钟",
          hour: "小时",
          calendarEvent: "日历事件",
          youReceivedAnEmailFrom: "你收到了一封来自",
          youSentAnEmailTo: "你发送了一封邮件给",
          youReadAnEmailFrom: "你读了一封邮件来自",
          youReceivedNewDataFromOura: "你收到了来自 Oura 的新数据",
        }
      },
      hi: {
        translation: {
          continueWithGoogle: "Google के साथ जारी रखें",
          continueWithEmail: "ईमेल के साथ जारी रखें",
          byContinuingYouAgreeToThe: "जारी रखने का अर्थ है कि आप सहमत हैं",
          termsOfService: "सेवा शर्तें",
          and: "और",
          privacyPolicy: "गोपनीयता नीति",
          emailPlaceholder: "आपका@ईमेल.com",
          goBack: "वापस जाएं",
          thatDoesntMatchWhatWeSent: "यह वही नहीं है जो हमने भेजा था",
          weJustSentACodeTo: "हमने अभी अभी एक कोड भेजा है",
          enterItHereToContinue: "जारी रखने के लिए इसे यहाँ दर्ज करें",
          wrongEmail: "गलत ईमेल",
          confirm: "पुष्टि करें",
          welcomeToVALT: "VALT में आपका स्वागत है",
          welcomeToVALTMarketplace: "VALT मार्केटप्लेस में आपका स्वागत है",
          marketplaceDataKeyDisclosure: "यहाँ आपकी डेटा कुंजी है। इससे आपको आपके द्वारा खरीदे गए डेटा तक पहुंच मिलती है, इसलिए आगे बढ़ने से पहले इसे कहीं कॉपी/पेस्ट कर लें और इसे कभी साझा न करें। यह केवल साइनअप पर दिखाई देती है। यदि खो जाती है, तो इस खाते द्वारा खरीदा गया डेटा अपरिवर्तनीय होगा।",
          dataKeyDisclosure: "यहाँ आपकी डेटा कुंजी है। आपको उपकरणों को लिंक करने और डेटा पुनर्प्राप्त करने के लिए इसकी आवश्यकता पड़ सकती है, इसलिए आगे बढ़ने से पहले इसे कहीं कॉपी/पेस्ट कर लें और इसे कभी साझा न करें। यह केवल साइनअप पर दिखाई देती है। यदि खो जाती है, तो इस खाते का डेटा पुनर्प्राप्त नहीं किया जा सकेगा।",
          copy: "कॉपी करें",
          copied: "कॉपी हो गया",
          ok: "ठीक है",
          withThisAppYoullBeAbleTo: "इस ऐप के साथ, आप सक्षम होंगे",
          secure: "सुरक्षित करें",
          interactWith: "के साथ इंटरैक्ट करें",
          monetize: "मुद्रीकरण करें",
          yourData: "आपके डेटा",
          howVALTSecuresData: "VALT आपको आपके ऐप्स और सेवाओं में उत्पन्न डेटा (जैसे कि GPS डेटा, ईमेल, ब्राउज़िंग इतिहास, खरीदारी डेटा, आदि) को कैप्चर करने की अनुमति देता है।",
          howVALTAllowsInteraction: "हमारा AI सहायक और व्यक्तिगत डेटा टाइमलाइन आपको आपके द्वारा निर्मित डेटा को पुनः देखने और आपके बारे में अंतर्दृष्टि प्राप्त करने में सक्षम बनाते हैं, जो पहले केवल Google और डिजिटल विपणक के लिए उपलब्ध था।",
          howVALTMonetizesData: "पर्याप्त उपयोगकर्ताओं के साथ, हमारा व्यक्तिगत डेटा मार्केटप्लेस जल्द ही आपको पहली बार आपके डेटा को बेचने की अनुमति देगा। अपने मित्रों को बताएं और आज ही इस आंदोलन को शुरू करने में मदद करें।",
          next: "अगला",
          back: "वापस",
          connectdVPN: "dVPN से कनेक्ट करें",
          connectdVPNInstructions: "स्क्रीन के निचले नेविगेशन बार से dVPN से कनेक्ट करें",
          connectDataSources: "डेटा स्रोतों से कनेक्ट करें",
          connectDataSourcesInstructions: "ऊपर-बाएं सेटिंग मेन्यू में जाकर नए डेटा स्रोत जोड़ें",
          gotIt: "समझ गया",
          noDataCollectedFor: "के लिए कोई डेटा एकत्र नहीं किया गया",
          tapForFastestConnections: "सबसे तेज कनेक्शनों के लिए टैप करें",
          gettingData: "डेटा प्राप्त हो रहा है",
          secureMoreDataSources: "और अधिक डेटा स्रोत सुरक्षित करें",
          upgradeVALT: "VALT+ में अपग्रेड करें",
          changeDate: "तिथि बदलें",
          account: "खाता",
          connectedApps: "कनेक्टेड ऐप्स",
          supportAndFAQ: "सहायता एवं FAQ",
          logOut: "लॉग आउट",
          copywright: "© 2025 HashCash, Inc",
          EULA: "EULA",
          timeline: "टाइमलाइन",
          chat: "चैट",
          moreInfo: "अधिक जानकारी",
          download: "डाउनलोड करें",
          upload: "अपलोड करें",
          youAreCurrentlyConnectedTo: "आप वर्तमान में इससे जुड़े हैं",
          updateConnection: "कनेक्शन अपडेट करें",
          hey: "अरे",
          welcomeBack: "स्वागत है",
          iveDetected: "मैंने पता लगाया है",
          leaksAssociatedWithYourData: "आपके डेटा से संबंधित लीक",
          youreNotConnectedToOurdVPNHeresAFastConnectionForYouIn: "आप हमारे dVPN से कनेक्टेड नहीं हैं। यहाँ आपके लिए एक तेज कनेक्शन है",
          yourAccount: "आपका खाता",
          firstName: "पहला नाम",
          lastName: "अंतिम नाम",
          email: "ईमेल",
          phone: "फोन",
          notProvided: "उपलब्ध नहीं",
          billing: "बिलिंग",
          deleteAccount: "खाता हटाएं",
          areYouSure: "क्या आप सुनिश्चित हैं?",
          yes: "हाँ",
          waitNevermind: "रुको, कोई बात नहीं",
          version: "संस्करण",
          takeControlOfYourPersonalDataTodayWithVALTPlus: "आज ही VALT+ के साथ अपने व्यक्तिगत डेटा पर नियंत्रण प्राप्त करें",
          annualPlan: "वार्षिक योजना",
          currentPlan: "वर्तमान योजना",
          whatsIncluded: "क्या शामिल है",
          decentralizedVPNForSecureBrowsing: "सुरक्षित ब्राउज़िंग के लिए विकेंद्रीकृत VPN",
          dVPNForUnlimitedDevices: "अनलिमिटेड डिवाइस के लिए dVPN",
          advancedBackupTools: "उन्नत बैकअप उपकरण",
          fullPersonalDataTimelineIntegratedWithDozensOfApps: "दर्जनों ऐप्स के साथ एकीकृत पूर्ण व्यक्तिगत डेटा टाइमलाइन",
          dataCleanup: "निरंतर डेटा सफाई",
          VALTWillForceBrokers: "VALT डेटा ब्रोकर्स को आपके व्यक्तिगत जानकारी हटाने के लिए मजबूर करेगा ताकि स्पैम कॉल और ईमेल कम हों",
          monthlyPlan: "मासिक योजना",
          twoYearPlan: "2-वर्षीय योजना",
          billedEveryTwoYears: "हर दो साल में बिल किया जाता है",
          billedAnnually: "वार्षिक बिलिंग",
          select: "चुनें",
          selectPaymentMethod: "भुगतान विधि चुनें",
          creditOrDebitCard: "क्रेडिट या डेबिट कार्ड",
          introPriceDisclosure: "प्रारंभिक कीमत आपकी सदस्यता की पहली अवधि के लिए मान्य है। प्रारंभिक कीमत द्वारा प्रदान की गई बचत वर्तमान नवीनीकरण कीमत से तुलना की जाती है",
          planUpdated: "योजना अपडेट हुई",
          somethingWentWrong: "कुछ गलत हो गया। कृपया बाद में पुन: प्रयास करें",
          updatePlan: "योजना अपडेट करें",
          welcomeToVALTPlus: "VALT+ में आपका स्वागत है",
          weNeedToCollectDetailsToCleanupData: "हम आपके डेटा की सफाई शुरू करने के लिए कुछ विवरण एकत्र करना चाहते हैं",
          pleaseEnterYourFirstName: "कृपया अपना पहला नाम दर्ज करें",
          pleaseEnterYourLastName: "कृपया अपना अंतिम नाम दर्ज करें",
          pleaseEnterYourPostalCode: "यदि आप अमेरिका में हैं, तो कृपया अपना पोस्टल कोड दर्ज करें - अन्यथा 0 दर्ज करें",
          pleaseEnterYourBirthday: "कृपया अपनी जन्मतिथि दर्ज करें",
          pleaseEnterYourPhoneNumber: "कृपया अपना फोन नंबर दर्ज करें",
          aboutVALTPlus: "VALT+ के बारे में",
          aboutVALTPlusDetails: "VALT आपके डेटा प्रबंधन में मदद करने के लिए उपकरणों का एक सेट प्रदान करता है। आज सदस्यता लेकर, आप हमारे व्यक्तिगत डेटा मार्केटप्लेस के निर्माण में मदद कर रहे हैं, जहाँ आप अपने डेटा के लिए भुगतान प्राप्त कर सकते हैं। VALT को आपके द्वारा VALT ऐप पर एकत्र किए गए डेटा तक कभी भी पहुंच नहीं होती।",
          planSummary: "योजना सारांश",
          unitedStates: "संयुक्त राज्य",
          salesTax: "बिक्री कर",
          total: "कुल",
          taxCountry: "कर देश",
          oneyearplan: "1-वर्षीय योजना",
          twoyearplan: "2-वर्षीय योजना",
          monthlyplan: "मासिक योजना",
          unlimiteddVPNAccess: "अनलिमिटेड dVPN एक्सेस",
          fullPersonalDataTimeline: "पूर्ण व्यक्तिगत डेटा टाइमलाइन",
          constantDataCleanup: "24/7 डेटा सफाई",
          serverIsUnavailable: "सर्वर उपलब्ध नहीं है",
          tapToConnect: "कनेक्ट करने के लिए टैप करें",
          connecting: "कनेक्ट हो रहा है",
          tapToDisconnect: "डिस्कनेक्ट करने के लिए टैप करें",
          connected: "कनेक्टेड",
          returnToCurrentConnection: "वर्तमान कनेक्शन पर वापस जाएं",
          disconnecting: "डिस्कनेक्ट हो रहा है",
          connectAccounts: "खातों को कनेक्ट करें",
          connectAccountsDisclosure: "अपने डेटा को सुरक्षित रखने के लिए अपने खातों को कनेक्ट करें। जारी रखने पर, आप गोपनीयता नीति और सेवा शर्तों से सहमत होते हैं।",
          thisDataSourceIsOnlyAvailableOnMobileDevices: "यह डेटा स्रोत केवल मोबाइल उपकरणों पर उपलब्ध है",
          show: "दिखाएँ",
          bank: "बैंक",
          onDashboard: "डैशबोर्ड पर",
          reauthorize: "पुनः अधिकृत करें",
          disconnect: "डिस्कनेक्ट करें",
          downloadTheCromeExtensionFrom: "अपने कंप्यूटर पर Chrome एक्सटेंशन डाउनलोड करें",
          andEnterTheFollowingCode: "और निम्न कोड दर्ज करें",
          linkDevice: "डिवाइस लिंक करें",
          toAccessYourDataFromThisDevice: "इस डिवाइस से डेटा एक्सेस करने के लिए, अपने खाते को अपने डेटा की से लिंक करें",
          linkAccount: "खाता लिंक करें",
          lostYourDataKey: "क्या आपका डेटा की खो गया है?",
          getNewKey: "नई चाबी प्राप्त करें",
          signOut: "साइन आउट करें",
          accessYourDataOnDesktop: "डेस्कटॉप पर डेटा एक्सेस करने के लिए, अपने खाते को अपने डेटा की से लिंक करें",
          dataKey: "डेटा की",
          enterYourDataKey: "अपना डेटा की दर्ज करें",
          paste: "पेस्ट करें",
          cancel: "रद्द करें",
          continue: "जारी रखें",
          confirmDevice: "डिवाइस की पुष्टि करें",
          weJustSentYouAnEmailToConfirmThisDevice: "हमने अभी इस डिवाइस की पुष्टि के लिए आपको ईमेल किया है",
          afterRespondingToEmail: "ईमेल का जवाब देने के बाद, एक्सेस पाने के लिए ऐप को बंद करें और फिर से खोलें",
          checkingForAccess: "एक्सेस की जांच हो रही है",
          wrongDevice: "गलत डिवाइस",
          thisDeviceIsCurrentlyConnectedToAnotherVALTAccount: "यह डिवाइस वर्तमान में किसी अन्य VALT खाते से जुड़ा हुआ है",
          enterCode: "कोड दर्ज करें",
          weJustEmailedAccountReset: "हमने अभी आपको खाता रीसेट कोड के लिए ईमेल किया है। जारी रखने के लिए इसे यहाँ दर्ज करें",
          accountResetDisclosure: "कृपया ध्यान दें कि खाता रीसेट करने के बाद, पिछली डेटा की से एकत्र किए गए सभी डेटा पुनर्प्राप्त नहीं किए जा सकेंगे, और आपको नए डेटा की के साथ सभी डिवाइस पर फिर से लॉगिन करना होगा",
          resetAccount: "खाता रीसेट करें",
          theCodeYouProvidedWasIncorrect: "आपके द्वारा दिया गया कोड गलत था",
          resetDevice: "डिवाइस रीसेट करें",
          VALTPlusDashboard: "VALT+ डैशबोर्ड",
          initDataCleanupMessage: "हम आपके डेटा की सफाई शुरू कर रहे हैं। जल्द ही वापस आएँ। कृपया ध्यान दें कि डेटा सफाई सेवाएँ वर्तमान में केवल अमेरिका में समर्थित हैं",
          selectDisplayAccount: "डिस्प्ले खाता चुनें",
          wereSendingYouToTheLoginPageForThisApp: "हम आपको इस ऐप के लॉगिन पृष्ठ पर भेज रहे हैं",
          connectAppSuccess: "पुष्टि हो गई। परिवर्तनों के प्रभावी होने के लिए अपने VALT ऐप पर वापस जाएँ",
          save74: "(74% बचत)",
          save66: "(66% बचत)",
          save68: "(68% बचत)",
          save60: "(60% बचत)",
          save20: "(20% बचत)",
          friend: "दोस्त",
          youJustArrived: "आप अभी पहुंचे हैं",
          youArrived: "आप पहुंचे",
          ago: "पहले",
          oneSecond: "1 सेकंड",
          oneMinute: "1 मिनट",
          oneHour: "1 घंटा",
          seconds: "सेकंड",
          minutes: "मिनट",
          hours: "घंटे",
          youVisitedThisLocation: "आपने इस स्थान का दौरा किया",
          youSpent: "आपने खर्च किया",
          near: "के पास",
          thisLocation: "इस स्थान",
          youTravelledFor: "आपने यात्रा की",
          towards: "की ओर",
          earth: "पृथ्वी पर कहीं",
          at: "में",
          Georgia: "जॉर्जिया",
          Colombia: "कोलम्बिया",
          SriLanka: "श्रीलंका",
          UnitedArabEmirates: "संयुक्त अरब अमीरात",
          Canada: "कनाडा",
          UnitedStates: "संयुक्त राज्य अमेरिका",
          Slovenia: "स्लोवेनिया",
          Belgium: "बेल्जियम",
          Bahamas: "बहामास",
          NorthMacedonia: "उत्तरी मैसेडोनिया",
          Ecuador: "इक्वाडोर",
          Malaysia: "मलेशिया",
          Montenegro: "मोंटेनेग्रो",
          Azerbaijan: "अज़रबैजान",
          Jamaica: "जमैका",
          Kazakhstan: "कजाखस्तान",
          Latvia: "लातविया",
          Argentina: "अर्जेंटीना",
          Russia: "रूस",
          TheNetherlands: "नीदरलैंड्स",
          Venezuela: "वेनेजुएला",
          Germany: "जर्मनी",
          Turkey: "तुर्की",
          Vietnam: "वियतनाम",
          Luxembourg: "लक्ज़मबर्ग",
          Thailand: "थाईलैंड",
          HongKong: "हांगकांग",
          Romania: "रोमानिया",
          Monaco: "मोनाको",
          Greece: "यूनान",
          IsleofMan: "मैन द्वीप",
          Pakistan: "पाकिस्तान",
          Uruguay: "उरुग्वे",
          Armenia: "आर्मेनिया",
          Liechtenstein: "लिचटेंस्टीन",
          Qatar: "कतर",
          Switzerland: "स्विट्जरलैंड",
          Egypt: "मिस्र",
          Bulgaria: "बल्गेरिया",
          Cyprus: "साइप्रस",
          Lithuania: "लिथुआनिया",
          Panama: "पनामा",
          Croatia: "क्रोएशिया",
          Slovakia: "स्लोवाकिया",
          Sweden: "स्वीडन",
          Philippines: "फिलीपींस",
          Macao: "मकाओ",
          Austria: "ऑस्ट्रिया",
          Portugal: "पुर्तगाल",
          Algeria: "अल्जीरिया",
          Australia: "ऑस्ट्रेलिया",
          SouthAfrica: "दक्षिण अफ्रीका",
          NewZealand: "न्यूज़ीलैंड",
          SaudiArabia: "सऊदी अरब",
          Bahrain: "बहरीन",
          China: "चीन",
          Estonia: "एस्टोनिया",
          Morocco: "मोरक्को",
          Cambodia: "कम्बोडिया",
          SouthKorea: "दक्षिण कोरिया",
          Singapore: "सिंगापुर",
          Ireland: "आयरलैंड",
          Iceland: "आइसलैंड",
          Türkiye: "तुर्की",
          Guatemala: "ग्वाटेमाला",
          PuertoRico: "पोर्टो रिको",
          Finland: "फिनलैंड",
          Greenland: "ग्रीनलैंड",
          Indonesia: "इंडोनेशिया",
          UnitedKingdom: "यूनाइटेड किंगडम",
          Serbia: "सर्बिया",
          Nigeria: "नाइजीरिया",
          BosniaandHerzegovina: "बोस्निया और हरज़ेगोविना",
          Poland: "पोलैंड",
          Netherlands: "नीदरलैंड्स",
          Italy: "इटली",
          France: "फ्रांस",
          Denmark: "डेनमार्क",
          Israel: "इजरायल",
          Mexico: "मेक्सिको",
          Norway: "नॉर्वे",
          Chile: "चिली",
          Malta: "माल्टा",
          Ukraine: "यूक्रेन",
          Brazil: "ब्राजील",
          Hungary: "हंगरी",
          Japan: "जापान",
          Libya: "लीबिया",
          Nepal: "नेपाल",
          Andorra: "एंडोरा",
          Moldova: "मोल्दोवा",
          Kenya: "केन्या",
          Albania: "अल्बानिया",
          India: "भारत",
          Taiwan: "ताइवान",
          Peru: "पेरू",
          Zambia: "ज़ाम्बिया",
          Bangladesh: "बांग्लादेश",
          Czechia: "चेकिया",
          DRCongo: "डीआर कांगो",
          Spain: "स्पेन",
          Afghanistan: "अफगानिस्तान",
          Angola: "अंगोला",
          Botswana: "बोत्सवाना",
          BurkinaFaso: "बुर्किना फ़ासो",
          Burundi: "बुरुंडी",
          CapeVerde: "केप वर्डे",
          Cameroon: "कैमरून",
          CentralAfricanRepublic: "मध्य अफ़्रीकी गणराज्य",
          Chad: "चाड",
          Comoros: "कोमोरोस",
          CongoRepublic: "कांगो गणराज्य",
          CoteDIvoire: "कोटे डी'इवोयर",
          Djibouti: "जिबूती",
          EquatorialGuinea: "इक्वेटोरियल गिनी",
          Eritrea: "इरिट्रिया",
          Eswatini: "एस्वातिनी",
          Ethiopia: "इथियोपिया",
          Gabon: "गैबोन",
          Gambia: "गाम्बिया",
          Ghana: "घाना",
          Guinea: "गिनी",
          GuineaBissau: "गिनी-बिसाऊ",
          Lesotho: "लेसोथो",
          Liberia: "लाइबेरिया",
          Madagascar: "मैडागास्कर",
          Malawi: "मालावी",
          Mali: "माली",
          Mauritania: "मौरिटानिया",
          Mauritius: "मॉरिशस",
          Mozambique: "मोजाम्बिक",
          Namibia: "नामिबिया",
          Niger: "नाइजर",
          Rwanda: "रवांडा",
          SaoTomeAndPrincipe: "साओ टोमे और प्रिंसिपे",
          Senegal: "सेनेगल",
          Seychelles: "सेशेल्स",
          SierraLeone: "सिएरा लियोन",
          Somalia: "सोमालिया",
          SouthSudan: "दक्षिण सूडान",
          Sudan: "सूडान",
          Tanzania: "तंजानिया",
          Tunisia: "ट्यूनीशिया",
          Uganda: "युगांडा",
          Zimbabwe: "जिम्बाब्वे",
          Bhutan: "भूटान",
          Iran: "ईरान",
          Iraq: "इराक",
          Jordan: "जॉर्डन",
          Kuwait: "कुवैत",
          Kyrgyzstan: "किर्गिस्तान",
          Laos: "लाओस",
          Lebanon: "लेबनान",
          Maldives: "मालदीव",
          Mongolia: "मंगोलिया",
          Myanmar: "म्यांमार",
          NorthKorea: "उत्तर कोरिया",
          Oman: "ओमान",
          Palestine: "फ़िलिस्तीन",
          Syria: "सीरिया",
          Tajikistan: "ताजिकिस्तान",
          Turkmenistan: "तुर्कमेनिस्तान",
          Uzbekistan: "उज़बेकिस्तान",
          Yemen: "यमन",
          Belarus: "बेलारूस",
          Kosovo: "कोसोवो",
          SanMarino: "सैन मैरीनो",
          AntiguaAndBarbuda: "एंटिगुआ और बारबुडा",
          Barbados: "बारबाडोस",
          Belize: "बेलीज़",
          Bolivia: "बोलीविया",
          Cuba: "क्यूबा",
          Dominica: "डोमिनिका",
          DominicanRepublic: "डोमिनिकन गणराज्य",
          ElSalvador: "एल साल्वाडोर",
          Grenada: "ग्रेनेडा",
          Guyana: "गुयाना",
          Haiti: "हैती",
          Honduras: "होंडुरस",
          Nicaragua: "निकारागुआ",
          Paraguay: "परागुए",
          SaintKittsAndNevis: "सेंट किट्स और नेविस",
          SaintLucia: "सेंट लूसिया",
          SaintVincentAndTheGrenadines: "सेंट विन्सेंट और ग्रेनेडाइंस",
          Suriname: "सुरीनाम",
          TrinidadAndTobago: "त्रिनिदाद और टोबैगो",
          youListenedTo: "आपने सुना",
          from: "से",
          by: "द्वारा",
          youWereCharged: "आपसे शुल्क लिया गया",
          youVisited: "आपने दौरा किया",
          youHadA: "आपका एक",
          minute: "मिनट",
          hour: "घंटा",
          calendarEvent: "कार्यक्रम",
          youReceivedAnEmailFrom: "आपको एक ईमेल मिला",
          youSentAnEmailTo: "आपने ईमेल भेजा",
          youReadAnEmailFrom: "आपने ईमेल पढ़ा",
          youReceivedNewDataFromOura: "आपको Oura से नए डेटा प्राप्त हुए",
        }
      },
      fr: {
        translation: {
          continueWithGoogle: "Continuer avec Google",
          continueWithEmail: "Continuer avec l'Email",
          byContinuingYouAgreeToThe: "En continuant, vous acceptez les",
          termsOfService: "Conditions d'utilisation",
          and: "et",
          privacyPolicy: "Politique de confidentialité",
          emailPlaceholder: "votre@email.com",
          goBack: "Retour",
          thatDoesntMatchWhatWeSent: "Cela ne correspond pas à ce que nous avons envoyé",
          weJustSentACodeTo: "Nous venons d'envoyer un code à",
          enterItHereToContinue: "Entrez-le ici pour continuer",
          wrongEmail: "Email incorrect",
          confirm: "Confirmer",
          welcomeToVALT: "Bienvenue sur VALT",
          welcomeToVALTMarketplace: "Bienvenue sur le marché VALT",
          marketplaceDataKeyDisclosure: "Voici votre clé de données. Elle vous permet d'accéder aux données que vous achetez, alors copiez-la et collez-la quelque part avant de continuer et ne la partagez jamais. Cela n'apparaît qu'à l'inscription. En cas de perte, les données achetées avec ce compte seront irrécupérables",
          dataKeyDisclosure: "Voici votre clé de données. Vous pourriez en avoir besoin pour lier des appareils et récupérer des données, alors copiez-la et collez-la quelque part avant de continuer et ne la partagez jamais. Cela n'apparaît qu'à l'inscription. En cas de perte, les données de ce compte seront irrécupérables",
          copy: "Copier",
          copied: "Copié",
          ok: "OK",
          withThisAppYoullBeAbleTo: "Avec cette application, vous pourrez",
          secure: "Sécuriser",
          interactWith: "Interagir avec",
          monetize: "Monétiser",
          yourData: "vos données",
          howVALTSecuresData: "VALT vous permet de capturer les données que vous créez sur vos applications et services, telles que les données GPS, les emails, l'historique de navigation, les données d'achat, et bien plus encore",
          howVALTAllowsInteraction: "Notre assistant IA et notre chronologie de données personnelles vous permettent de consulter les données que vous avez créées et d'en tirer des informations auparavant accessibles uniquement à Google et aux spécialistes du marketing digital",
          howVALTMonetizesData: "Avec un nombre suffisant d'utilisateurs, notre marché de données personnelles vous permettra bientôt de vendre vos données pour la première fois. Parrainez vos amis et aidez à lancer le mouvement dès aujourd'hui",
          next: "Suivant",
          back: "Retour",
          connectdVPN: "Se connecter au dVPN",
          connectdVPNInstructions: "Connectez-vous au dVPN depuis la barre de navigation en bas de l'écran",
          connectDataSources: "Connecter les sources de données",
          connectDataSourcesInstructions: "Ajoutez de nouvelles sources de données en accédant au menu des paramètres en haut à gauche",
          gotIt: "Compris",
          noDataCollectedFor: "Aucune donnée collectée pour",
          tapForFastestConnections: "Appuyez pour obtenir les connexions les plus rapides",
          gettingData: "Récupération des données",
          secureMoreDataSources: "Sécurisez davantage de sources de données",
          upgradeVALT: "Passez à VALT+",
          changeDate: "Modifier la date",
          account: "Compte",
          connectedApps: "Applications connectées",
          supportAndFAQ: "Support & FAQ",
          logOut: "Déconnexion",
          copywright: "© 2025 HashCash, Inc",
          EULA: "EULA",
          timeline: "Chronologie",
          chat: "Chat",
          moreInfo: "Plus d'informations",
          download: "Télécharger",
          upload: "Téléverser",
          youAreCurrentlyConnectedTo: "Vous êtes actuellement connecté à",
          updateConnection: "Mettre à jour la connexion",
          hey: "Salut",
          welcomeBack: "Bon retour",
          iveDetected: "J'ai détecté",
          leaksAssociatedWithYourData: "Fuites associées à vos données",
          youreNotConnectedToOurdVPNHeresAFastConnectionForYouIn: "Vous n'êtes pas connecté à notre dVPN. Voici une connexion rapide pour vous à",
          yourAccount: "Votre compte",
          firstName: "Prénom",
          lastName: "Nom",
          email: "Email",
          phone: "Téléphone",
          notProvided: "Non fourni",
          billing: "Facturation",
          deleteAccount: "Supprimer le compte",
          areYouSure: "Êtes-vous sûr ?",
          yes: "Oui",
          waitNevermind: "Attendez, laissez tomber",
          version: "Version",
          takeControlOfYourPersonalDataTodayWithVALTPlus: "Prenez le contrôle de vos données personnelles dès aujourd'hui avec VALT+",
          annualPlan: "Plan annuel",
          currentPlan: "Plan actuel",
          whatsIncluded: "Ce qui est inclus",
          decentralizedVPNForSecureBrowsing: "VPN décentralisé pour une navigation sécurisée",
          dVPNForUnlimitedDevices: "dVPN pour appareils illimités",
          advancedBackupTools: "Outils de sauvegarde avancés",
          fullPersonalDataTimelineIntegratedWithDozensOfApps: "Chronologie complète des données personnelles intégrée à des dizaines d'applications",
          dataCleanup: "Nettoyage continu des données",
          VALTWillForceBrokers: "VALT obligera les courtiers en données à supprimer vos informations personnelles afin de réduire les appels et emails indésirables",
          monthlyPlan: "Plan mensuel",
          twoYearPlan: "Plan de 2 ans",
          billedEveryTwoYears: "Facturé tous les deux ans",
          billedAnnually: "Facturé annuellement",
          select: "Sélectionner",
          selectPaymentMethod: "Sélectionnez le mode de paiement",
          creditOrDebitCard: "Carte de crédit ou de débit",
          introPriceDisclosure: "Le prix d'introduction est valable pour la première période de votre abonnement. Les économies réalisées sont comparées au prix de renouvellement actuel",
          planUpdated: "Plan mis à jour",
          somethingWentWrong: "Un problème est survenu. Veuillez réessayer plus tard",
          updatePlan: "Mettre à jour le plan",
          welcomeToVALTPlus: "Bienvenue sur VALT+",
          weNeedToCollectDetailsToCleanupData: "Nous devons recueillir quelques informations pour commencer à nettoyer vos données",
          pleaseEnterYourFirstName: "Veuillez saisir votre prénom",
          pleaseEnterYourLastName: "Veuillez saisir votre nom de famille",
          pleaseEnterYourPostalCode: "Si vous êtes aux États-Unis, veuillez saisir votre code postal - sinon, mettez 0",
          pleaseEnterYourBirthday: "Veuillez saisir votre date de naissance",
          pleaseEnterYourPhoneNumber: "Veuillez saisir votre numéro de téléphone",
          aboutVALTPlus: "À propos de VALT+",
          aboutVALTPlusDetails: "VALT propose une série d'outils pour vous aider à gérer vos données. En vous abonnant aujourd'hui, vous nous aidez à construire notre marché de données personnelles où vous pouvez être rémunéré pour vos données. VALT n'a jamais accès aux données que vous collectez via l'application VALT.",
          planSummary: "Résumé du plan",
          unitedStates: "États-Unis",
          salesTax: "Taxe de vente",
          total: "Total",
          taxCountry: "Pays imposable",
          oneyearplan: "Plan d'un an",
          twoyearplan: "Plan de deux ans",
          monthlyplan: "Plan mensuel",
          unlimiteddVPNAccess: "Accès illimité au dVPN",
          fullPersonalDataTimeline: "Chronologie complète des données personnelles",
          constantDataCleanup: "Nettoyage des données 24/7",
          serverIsUnavailable: "Le serveur n'est pas disponible",
          tapToConnect: "Appuyez pour connecter",
          connecting: "Connexion en cours",
          tapToDisconnect: "Appuyez pour déconnecter",
          connected: "Connecté",
          returnToCurrentConnection: "Revenir à la connexion actuelle",
          disconnecting: "Déconnexion en cours",
          connectAccounts: "Connecter les comptes",
          connectAccountsDisclosure: "Connectez vos comptes pour sécuriser vos données. En continuant, vous acceptez la politique de confidentialité et les conditions d'utilisation.",
          thisDataSourceIsOnlyAvailableOnMobileDevices: "Cette source de données est uniquement disponible sur les appareils mobiles",
          show: "Afficher",
          bank: "Banque",
          onDashboard: "sur le tableau de bord",
          reauthorize: "Réautoriser",
          disconnect: "Déconnecter",
          downloadTheCromeExtensionFrom: "Téléchargez l'extension Chrome sur votre ordinateur depuis",
          andEnterTheFollowingCode: "et entrez le code suivant",
          linkDevice: "Lier l'appareil",
          toAccessYourDataFromThisDevice: "Pour accéder à vos données depuis cet appareil, liez votre compte avec votre clé de données",
          linkAccount: "Lier le compte",
          lostYourDataKey: "Vous avez perdu votre clé de données ?",
          getNewKey: "Obtenir une nouvelle clé",
          signOut: "Déconnexion",
          accessYourDataOnDesktop: "Pour accéder à vos données sur ordinateur, liez votre compte avec votre clé de données",
          dataKey: "Clé de données",
          enterYourDataKey: "Entrez votre clé de données",
          paste: "Coller",
          cancel: "Annuler",
          continue: "Continuer",
          confirmDevice: "Confirmer l'appareil",
          weJustSentYouAnEmailToConfirmThisDevice: "Nous venons de vous envoyer un e-mail pour confirmer cet appareil",
          afterRespondingToEmail: "Après avoir répondu à l'e-mail, fermez et rouvrez l'application pour accéder",
          checkingForAccess: "Vérification de l'accès",
          wrongDevice: "Mauvais appareil",
          thisDeviceIsCurrentlyConnectedToAnotherVALTAccount: "Cet appareil est actuellement lié à un autre compte VALT",
          enterCode: "Entrez le code",
          weJustEmailedAccountReset: "Nous venons de vous envoyer un code de réinitialisation de compte par e-mail. Entrez-le ici pour continuer",
          accountResetDisclosure: "Veuillez noter qu'après la réinitialisation de votre compte, toutes les données collectées avec l'ancienne clé de données ne seront pas récupérables, et vous devrez vous reconnecter sur tous vos appareils avec la nouvelle clé de données",
          resetAccount: "Réinitialiser le compte",
          theCodeYouProvidedWasIncorrect: "Le code que vous avez fourni est incorrect",
          resetDevice: "Réinitialiser l'appareil",
          VALTPlusDashboard: "Tableau de bord VALT+",
          initDataCleanupMessage: "Nous commençons à nettoyer vos données. Revenez bientôt. Veuillez noter que les services de nettoyage de données sont actuellement disponibles uniquement aux États-Unis.",
          selectDisplayAccount: "Sélectionnez le compte d'affichage",
          wereSendingYouToTheLoginPageForThisApp: "Nous vous redirigeons vers la page de connexion de cette application",
          connectAppSuccess: "Confirmé. Retournez sur votre application VALT pour que les changements prennent effet",
          save74: "(Économisez 74%)",
          save66: "(Économisez 66%)",
          save68: "(Économisez 68%)",
          save60: "(Économisez 60%)",
          save20: "(Économisez 20%)",
          friend: "ami",
          youJustArrived: "Vous venez d'arriver",
          youArrived: "Vous êtes arrivé",
          ago: "il y a",
          oneSecond: "1 seconde",
          oneMinute: "1 minute",
          oneHour: "1 heure",
          seconds: "secondes",
          minutes: "minutes",
          hours: "heures",
          youVisitedThisLocation: "Vous avez visité cet endroit",
          youSpent: "Vous avez passé",
          near: "près de",
          thisLocation: "cet endroit",
          youTravelledFor: "Vous avez voyagé pendant",
          towards: "vers",
          earth: "Quelque part sur Terre",
          at: "à",
          Georgia: "Géorgie",
          Colombia: "Colombie",
          SriLanka: "Sri Lanka",
          UnitedArabEmirates: "Émirats arabes unis",
          Canada: "Canada",
          UnitedStates: "États-Unis",
          Slovenia: "Slovénie",
          Belgium: "Belgique",
          Bahamas: "Bahamas",
          NorthMacedonia: "Macédoine du Nord",
          Ecuador: "Équateur",
          Malaysia: "Malaisie",
          Montenegro: "Monténégro",
          Azerbaijan: "Azerbaïdjan",
          Jamaica: "Jamaïque",
          Kazakhstan: "Kazakhstan",
          Latvia: "Lettonie",
          Argentina: "Argentine",
          Russia: "Russie",
          TheNetherlands: "Pays-Bas",
          Venezuela: "Venezuela",
          Germany: "Allemagne",
          Turkey: "Turquie",
          Vietnam: "Vietnam",
          Luxembourg: "Luxembourg",
          Thailand: "Thaïlande",
          HongKong: "Hong Kong",
          Romania: "Roumanie",
          Monaco: "Monaco",
          Greece: "Grèce",
          IsleofMan: "Île de Man",
          Pakistan: "Pakistan",
          Uruguay: "Uruguay",
          Armenia: "Arménie",
          Liechtenstein: "Liechtenstein",
          Qatar: "Qatar",
          Switzerland: "Suisse",
          Egypt: "Égypte",
          Bulgaria: "Bulgarie",
          Cyprus: "Chypre",
          Lithuania: "Lituanie",
          Panama: "Panama",
          Croatia: "Croatie",
          Slovakia: "Slovaquie",
          Sweden: "Suède",
          Philippines: "Philippines",
          Macao: "Macao",
          Austria: "Autriche",
          Portugal: "Portugal",
          Algeria: "Algérie",
          Australia: "Australie",
          SouthAfrica: "Afrique du Sud",
          NewZealand: "Nouvelle-Zélande",
          SaudiArabia: "Arabie saoudite",
          Bahrain: "Bahreïn",
          China: "Chine",
          Estonia: "Estonie",
          Morocco: "Maroc",
          Cambodia: "Cambodge",
          SouthKorea: "Corée du Sud",
          Singapore: "Singapour",
          Ireland: "Irlande",
          Iceland: "Islande",
          Türkiye: "Türkiye",
          Guatemala: "Guatemala",
          PuertoRico: "Porto Rico",
          Finland: "Finlande",
          Greenland: "Groenland",
          Indonesia: "Indonésie",
          UnitedKingdom: "Royaume-Uni",
          Serbia: "Serbie",
          Nigeria: "Nigéria",
          BosniaandHerzegovina: "Bosnie-Herzégovine",
          Poland: "Pologne",
          Netherlands: "Pays-Bas",
          Italy: "Italie",
          France: "France",
          Denmark: "Danemark",
          Israel: "Israël",
          Mexico: "Mexique",
          Norway: "Norvège",
          Chile: "Chili",
          Malta: "Malte",
          Ukraine: "Ukraine",
          Brazil: "Brésil",
          Hungary: "Hongrie",
          Japan: "Japon",
          Libya: "Libye",
          Nepal: "Népal",
          Andorra: "Andorre",
          Moldova: "Moldavie",
          Kenya: "Kenya",
          Albania: "Albanie",
          India: "Inde",
          Taiwan: "Taïwan",
          Peru: "Pérou",
          Zambia: "Zambie",
          Bangladesh: "Bangladesh",
          Czechia: "Tchéquie",
          DRCongo: "République démocratique du Congo",
          Spain: "Espagne",
          Afghanistan: "Afghanistan",
          Angola: "Angola",
          Botswana: "Botswana",
          BurkinaFaso: "Burkina Faso",
          Burundi: "Burundi",
          CapeVerde: "Cap-Vert",
          Cameroon: "Cameroun",
          CentralAfricanRepublic: "République centrafricaine",
          Chad: "Tchad",
          Comoros: "Comores",
          CongoRepublic: "République du Congo",
          CoteDIvoire: "Côte d'Ivoire",
          Djibouti: "Djibouti",
          EquatorialGuinea: "Guinée équatoriale",
          Eritrea: "Érythrée",
          Eswatini: "Eswatini",
          Ethiopia: "Éthiopie",
          Gabon: "Gabon",
          Gambia: "Gambie",
          Ghana: "Ghana",
          Guinea: "Guinée",
          GuineaBissau: "Guinée-Bissau",
          Lesotho: "Lesotho",
          Liberia: "Libéria",
          Madagascar: "Madagascar",
          Malawi: "Malawi",
          Mali: "Mali",
          Mauritania: "Mauritanie",
          Mauritius: "Maurice",
          Mozambique: "Mozambique",
          Namibia: "Namibie",
          Niger: "Niger",
          Rwanda: "Rwanda",
          SaoTomeAndPrincipe: "Sao Tomé-et-Principe",
          Senegal: "Sénégal",
          Seychelles: "Seychelles",
          SierraLeone: "Sierra Leone",
          Somalia: "Somalie",
          SouthSudan: "Soudan du Sud",
          Sudan: "Soudan",
          Tanzania: "Tanzanie",
          Tunisia: "Tunisie",
          Uganda: "Ouganda",
          Zimbabwe: "Zimbabwe",
          Bhutan: "Bhoutan",
          Iran: "Iran",
          Iraq: "Irak",
          Jordan: "Jordanie",
          Kuwait: "Koweït",
          Kyrgyzstan: "Kirghizistan",
          Laos: "Laos",
          Lebanon: "Liban",
          Maldives: "Maldives",
          Mongolia: "Mongolie",
          Myanmar: "Birmanie",
          NorthKorea: "Corée du Nord",
          Oman: "Oman",
          Palestine: "Palestine",
          Syria: "Syrie",
          Tajikistan: "Tadjikistan",
          Turkmenistan: "Turkménistan",
          Uzbekistan: "Ouzbékistan",
          Yemen: "Yémen",
          Belarus: "Biélorussie",
          Kosovo: "Kosovo",
          SanMarino: "Saint-Marin",
          AntiguaAndBarbuda: "Antigua-et-Barbuda",
          Barbados: "Barbade",
          Belize: "Belize",
          Bolivia: "Bolivie",
          Cuba: "Cuba",
          Dominica: "Dominique",
          DominicanRepublic: "République dominicaine",
          ElSalvador: "El Salvador",
          Grenada: "Grenade",
          Guyana: "Guyana",
          Haiti: "Haïti",
          Honduras: "Honduras",
          Nicaragua: "Nicaragua",
          Paraguay: "Paraguay",
          SaintKittsAndNevis: "Saint-Christophe-et-Niévès",
          SaintLucia: "Sainte-Lucie",
          SaintVincentAndTheGrenadines: "Saint-Vincent-et-les-Grenadines",
          Suriname: "Suriname",
          TrinidadAndTobago: "Trinité-et-Tobago",
          youListenedTo: "Vous avez écouté",
          from: "de",
          by: "par",
          youWereCharged: "Vous avez été facturé",
          youVisited: "Vous avez visité",
          youHadA: "Vous avez eu un",
          minute: "minute",
          hour: "heure",
          calendarEvent: "événement de calendrier",
          youReceivedAnEmailFrom: "Vous avez reçu un email de",
          youSentAnEmailTo: "Vous avez envoyé un email à",
          youReadAnEmailFrom: "Vous avez lu un email de",
          youReceivedNewDataFromOura: "Vous avez reçu de nouvelles données de Oura",
        }
      },
      ar: {
        translation: {
          continueWithGoogle: "استمر مع جوجل",
          continueWithEmail: "استمر مع البريد الإلكتروني",
          byContinuingYouAgreeToThe: "بمتابعتك، فإنك توافق على",
          termsOfService: "شروط الخدمة",
          and: "و",
          privacyPolicy: "سياسة الخصوصية",
          emailPlaceholder: "بريدك@example.com",
          goBack: "العودة",
          thatDoesntMatchWhatWeSent: "هذا لا يتطابق مع ما أرسلناه",
          weJustSentACodeTo: "لقد أرسلنا للتو رمزًا إلى",
          enterItHereToContinue: "أدخله هنا للمتابعة",
          wrongEmail: "البريد الإلكتروني غير صحيح",
          confirm: "تأكيد",
          welcomeToVALT: "مرحبًا بك في VALT",
          welcomeToVALTMarketplace: "مرحبًا بك في سوق VALT",
          marketplaceDataKeyDisclosure: "هذه هي مفتاح بياناتك. يتيح لك الوصول إلى البيانات التي تشتريها، لذا انسخها والصقها في مكان ما قبل المتابعة ولا تشاركها مطلقًا. تظهر هذه الرسالة فقط عند التسجيل. إذا فُقدت، فلن يمكن استرداد البيانات التي تم شراؤها بهذا الحساب",
          dataKeyDisclosure: "هذه هي مفتاح بياناتك. قد تحتاج إليها لربط الأجهزة واسترداد البيانات، لذا انسخها والصقها في مكان ما قبل المتابعة ولا تشاركها مطلقًا. تظهر هذه الرسالة فقط عند التسجيل. إذا فُقدت، فلن يمكن استرداد بيانات هذا الحساب",
          copy: "نسخ",
          copied: "تم النسخ",
          ok: "حسنًا",
          withThisAppYoullBeAbleTo: "بهذا التطبيق، ستتمكن من",
          secure: "تأمين",
          interactWith: "التفاعل مع",
          monetize: "تحقيق الدخل من",
          yourData: "بياناتك",
          howVALTSecuresData: "يتيح لك VALT التقاط البيانات التي تنشئها في تطبيقاتك وخدماتك، مثل بيانات GPS، والبريد الإلكتروني، وتاريخ التصفح، وبيانات الشراء، وأكثر من ذلك بكثير",
          howVALTAllowsInteraction: "يساعدك مساعدنا الذكي وخط الزمن الخاص بالبيانات الشخصية على الرجوع إلى البيانات التي أنشأتها والحصول على رؤى عن نفسك كانت متاحة سابقًا فقط لجوجل والمسوقين الرقميين",
          howVALTMonetizesData: "مع عدد كافٍ من المستخدمين، سيسمح لك سوق البيانات الشخصية لدينا قريبًا ببيع بياناتك لأول مرة. أحِل أصدقائك وساعد في بدء الحركة اليوم",
          next: "التالي",
          back: "العودة",
          connectdVPN: "اتصل بـ dVPN",
          connectdVPNInstructions: "اتصل بـ dVPN من شريط التنقل في أسفل الشاشة",
          connectDataSources: "اتصل بمصادر البيانات",
          connectDataSourcesInstructions: "أضف مصادر بيانات جديدة عن طريق الوصول إلى قائمة الإعدادات في أعلى اليسار",
          gotIt: "فهمت",
          noDataCollectedFor: "لم يتم جمع بيانات لـ",
          tapForFastestConnections: "اضغط للحصول على أسرع الاتصالات",
          gettingData: "جاري جلب البيانات",
          secureMoreDataSources: "قم بتأمين المزيد من مصادر البيانات",
          upgradeVALT: "قم بالترقية إلى VALT+",
          changeDate: "غيّر التاريخ",
          account: "الحساب",
          connectedApps: "التطبيقات المتصلة",
          supportAndFAQ: "الدعم والأسئلة الشائعة",
          logOut: "تسجيل الخروج",
          copywright: "© 2025 هاش كاش، Inc",
          EULA: "اتفاقية ترخيص المستخدم النهائي",
          timeline: "الخط الزمني",
          chat: "دردشة",
          moreInfo: "مزيد من المعلومات",
          download: "تنزيل",
          upload: "رفع",
          youAreCurrentlyConnectedTo: "أنت متصل حاليًا بـ",
          updateConnection: "تحديث الاتصال",
          hey: "مرحبًا",
          welcomeBack: "أهلاً بعودتك",
          iveDetected: "لقد اكتشفت",
          leaksAssociatedWithYourData: "التسريبات المرتبطة ببياناتك",
          youreNotConnectedToOurdVPNHeresAFastConnectionForYouIn: "أنت لست متصلاً بـ dVPN الخاص بنا. إليك اتصال سريع لـ",
          yourAccount: "حسابك",
          firstName: "الاسم الأول",
          lastName: "اسم العائلة",
          email: "إيميل",
          phone: "الهاتف",
          notProvided: "غير مُقدم",
          billing: "الفواتير",
          deleteAccount: "حذف الحساب",
          areYouSure: "هل أنت متأكد؟",
          yes: "نعم",
          waitNevermind: "انتظر، لا بأس",
          version: "الإصدار",
          takeControlOfYourPersonalDataTodayWithVALTPlus: "تحكم في بياناتك الشخصية اليوم مع VALT+",
          annualPlan: "الخطة السنوية",
          currentPlan: "الخطة الحالية",
          whatsIncluded: "ما هو متضمن",
          decentralizedVPNForSecureBrowsing: "VPN لامركزي للتصفح الآمن",
          dVPNForUnlimitedDevices: "dVPN لأجهزة غير محدودة",
          advancedBackupTools: "أدوات النسخ الاحتياطي المتقدمة",
          fullPersonalDataTimelineIntegratedWithDozensOfApps: "خط زمني كامل للبيانات الشخصية مدمج مع عشرات التطبيقات",
          dataCleanup: "تنظيف البيانات المستمر",
          VALTWillForceBrokers: "سيجبر VALT وسطاء البيانات على حذف معلوماتك الشخصية لتقليل المكالمات والرسائل المزعجة",
          monthlyPlan: "الخطة الشهرية",
          twoYearPlan: "الخطة لمدة سنتين",
          billedEveryTwoYears: "يتم الفوترة كل سنتين",
          billedAnnually: "يتم الفوترة سنويًا",
          select: "اختر",
          selectPaymentMethod: "اختر طريقة الدفع",
          creditOrDebitCard: "بطاقة ائتمان أو خصم",
          introPriceDisclosure: "السعر التمهيدي صالح للفترة الأولى من اشتراكك. يتم مقارنة التوفير الناتج عن السعر التمهيدي مع سعر التجديد الحالي",
          planUpdated: "تم تحديث الخطة",
          somethingWentWrong: "حدث خطأ ما. يرجى المحاولة مرة أخرى لاحقًا",
          updatePlan: "تحديث الخطة",
          welcomeToVALTPlus: "مرحبًا بك في VALT+",
          weNeedToCollectDetailsToCleanupData: "نحتاج إلى جمع بعض التفاصيل لبدء تنظيف بياناتك",
          pleaseEnterYourFirstName: "يرجى إدخال اسمك الأول",
          pleaseEnterYourLastName: "يرجى إدخال اسم عائلتك",
          pleaseEnterYourPostalCode: "إذا كنت في الولايات المتحدة، يرجى إدخال الرمز البريدي - وإلا أدخل 0",
          pleaseEnterYourBirthday: "يرجى إدخال تاريخ ميلادك",
          pleaseEnterYourPhoneNumber: "يرجى إدخال رقم هاتفك",
          aboutVALTPlus: "حول VALT+",
          aboutVALTPlusDetails: "يقدم VALT مجموعة من الأدوات لمساعدتك في إدارة بياناتك. بالاشتراك اليوم، فإنك تساعدنا على بناء سوق البيانات الشخصية حيث يمكنك الحصول على أجر مقابل بياناتك. لا يصل VALT أبدًا إلى البيانات التي تجمعها في تطبيق VALT.",
          planSummary: "ملخص الخطة",
          unitedStates: "الولايات المتحدة",
          salesTax: "ضريبة المبيعات",
          total: "الإجمالي",
          taxCountry: "دولة الضرائب",
          oneyearplan: "خطة لمدة سنة",
          twoyearplan: "خطة لمدة سنتين",
          monthlyplan: "خطة شهرية",
          unlimiteddVPNAccess: "وصول غير محدود إلى dVPN",
          fullPersonalDataTimeline: "خط زمني كامل للبيانات الشخصية",
          constantDataCleanup: "تنظيف البيانات على مدار 24/7",
          serverIsUnavailable: "الخادم غير متاح",
          tapToConnect: "اضغط للاتصال",
          connecting: "جاري الاتصال",
          tapToDisconnect: "اضغط لقطع الاتصال",
          connected: "متصل",
          returnToCurrentConnection: "العودة إلى الاتصال الحالي",
          disconnecting: "جاري قطع الاتصال",
          connectAccounts: "ربط الحسابات",
          connectAccountsDisclosure: "قم بربط حساباتك لتأمين بياناتك. بالاستمرار، فإنك توافق على سياسة الخصوصية وشروط الخدمة.",
          thisDataSourceIsOnlyAvailableOnMobileDevices: "هذا المصدر متاح فقط على الأجهزة المحمولة",
          show: "عرض",
          bank: "البنك",
          onDashboard: "على لوحة التحكم",
          reauthorize: "إعادة التفويض",
          disconnect: "قطع الاتصال",
          downloadTheCromeExtensionFrom: "قم بتنزيل إضافة Chrome على جهاز الكمبيوتر من",
          andEnterTheFollowingCode: "وأدخل الرمز التالي",
          linkDevice: "ربط الجهاز",
          toAccessYourDataFromThisDevice: "للوصول إلى بياناتك من هذا الجهاز، اربط حسابك بمفتاح البيانات الخاص بك",
          linkAccount: "ربط الحساب",
          lostYourDataKey: "هل فقدت مفتاح بياناتك؟",
          getNewKey: "احصل على مفتاح جديد",
          signOut: "تسجيل الخروج",
          accessYourDataOnDesktop: "للوصول إلى بياناتك على الكمبيوتر، اربط حسابك بمفتاح البيانات الخاص بك",
          dataKey: "مفتاح البيانات",
          enterYourDataKey: "أدخل مفتاح بياناتك",
          paste: "لصق",
          cancel: "إلغاء",
          continue: "استمر",
          confirmDevice: "تأكيد الجهاز",
          weJustSentYouAnEmailToConfirmThisDevice: "لقد أرسلنا لك بريدًا إلكترونيًا لتأكيد هذا الجهاز",
          afterRespondingToEmail: "بعد الرد على البريد الإلكتروني، أغلق التطبيق وأعد فتحه للوصول",
          checkingForAccess: "جار التحقق من الوصول",
          wrongDevice: "جهاز خاطئ",
          thisDeviceIsCurrentlyConnectedToAnotherVALTAccount: "هذا الجهاز مرتبط حاليًا بحساب VALT آخر",
          enterCode: "أدخل الرمز",
          weJustEmailedAccountReset: "لقد أرسلنا لك للتو رمز إعادة ضبط الحساب عبر البريد الإلكتروني. أدخله هنا للمتابعة",
          accountResetDisclosure: "يرجى ملاحظة أنه بعد إعادة ضبط حسابك، لن يمكن استرداد جميع البيانات التي تم جمعها بالمفتاح السابق، وستحتاج إلى تسجيل الدخول مجددًا على جميع الأجهزة باستخدام المفتاح الجديد",
          resetAccount: "إعادة ضبط الحساب",
          theCodeYouProvidedWasIncorrect: "الرمز الذي أدخلته غير صحيح",
          resetDevice: "إعادة ضبط الجهاز",
          VALTPlusDashboard: "لوحة تحكم VALT+",
          initDataCleanupMessage: "نحن نبدأ في تنظيف بياناتك. عد لاحقًا. يرجى ملاحظة أن خدمات تنظيف البيانات مدعومة حاليًا فقط في الولايات المتحدة",
          selectDisplayAccount: "اختر حساب العرض",
          wereSendingYouToTheLoginPageForThisApp: "نحن نوجهك إلى صفحة تسجيل الدخول لهذا التطبيق",
          connectAppSuccess: "تم التأكيد. عد إلى تطبيق VALT لتفعيل التغييرات",
          save74: "(وفر 74%)",
          save66: "(وفر 66%)",
          save68: "(وفر 68%)",
          save60: "(وفر 60%)",
          save20: "(وفر 20%)",
          friend: "صديق",
          youJustArrived: "لقد وصلت للتو",
          youArrived: "لقد وصلت",
          ago: "منذ",
          oneSecond: "1 ثانية",
          oneMinute: "1 دقيقة",
          oneHour: "1 ساعة",
          seconds: "ثوانٍ",
          minutes: "دقائق",
          hours: "ساعات",
          youVisitedThisLocation: "لقد زرت هذا الموقع",
          youSpent: "لقد قضيت",
          near: "بالقرب من",
          thisLocation: "هذا الموقع",
          youTravelledFor: "سافرت لمدة",
          towards: "نحو",
          earth: "في مكان ما على الأرض",
          at: "في",
          Georgia: "جورجيا",
          Colombia: "كولومبيا",
          SriLanka: "سريلانكا",
          UnitedArabEmirates: "الإمارات العربية المتحدة",
          Canada: "كندا",
          UnitedStates: "الولايات المتحدة",
          Slovenia: "سلوفينيا",
          Belgium: "بلجيكا",
          Bahamas: "باهاماس",
          NorthMacedonia: "مقدونيا الشمالية",
          Ecuador: "الإكوادور",
          Malaysia: "ماليزيا",
          Montenegro: "الجبل الأسود",
          Azerbaijan: "أذربيجان",
          Jamaica: "جامايكا",
          Kazakhstan: "كازاخستان",
          Latvia: "لاتفيا",
          Argentina: "الأرجنتين",
          Russia: "روسيا",
          TheNetherlands: "هولندا",
          Venezuela: "فنزويلا",
          Germany: "ألمانيا",
          Turkey: "تركيا",
          Vietnam: "فيتنام",
          Luxembourg: "لوكسمبورغ",
          Thailand: "تايلاند",
          HongKong: "هونغ كونغ",
          Romania: "رومانيا",
          Monaco: "موناكو",
          Greece: "اليونان",
          IsleofMan: "جزيرة مان",
          Pakistan: "باكستان",
          Uruguay: "أوروغواي",
          Armenia: "أرمينيا",
          Liechtenstein: "ليختنشتاين",
          Qatar: "قطر",
          Switzerland: "سويسرا",
          Egypt: "مصر",
          Bulgaria: "بلغاريا",
          Cyprus: "قبرص",
          Lithuania: "ليتوانيا",
          Panama: "بنما",
          Croatia: "كرواتيا",
          Slovakia: "سلوفاكيا",
          Sweden: "السويد",
          Philippines: "الفلبين",
          Macao: "ماكاو",
          Austria: "النمسا",
          Portugal: "البرتغال",
          Algeria: "الجزائر",
          Australia: "أستراليا",
          SouthAfrica: "جنوب أفريقيا",
          NewZealand: "نيوزيلندا",
          SaudiArabia: "المملكة العربية السعودية",
          Bahrain: "البحرين",
          China: "الصين",
          Estonia: "إستونيا",
          Morocco: "المغرب",
          Cambodia: "كمبوديا",
          SouthKorea: "كوريا الجنوبية",
          Singapore: "سنغافورة",
          Ireland: "أيرلندا",
          Iceland: "آيسلندا",
          Türkiye: "تركيا",
          Guatemala: "غواتيمالا",
          PuertoRico: "بورتوريكو",
          Finland: "فنلندا",
          Greenland: "غرينلاند",
          Indonesia: "إندونيسيا",
          UnitedKingdom: "المملكة المتحدة",
          Serbia: "صربيا",
          Nigeria: "نيجيريا",
          BosniaandHerzegovina: "البوسنة والهرسك",
          Poland: "بولندا",
          Netherlands: "هولندا",
          Italy: "إيطاليا",
          France: "فرنسا",
          Denmark: "الدنمارك",
          Israel: "إسرائيل",
          Mexico: "المكسيك",
          Norway: "النرويج",
          Chile: "تشيلي",
          Malta: "مالطا",
          Ukraine: "أوكرانيا",
          Brazil: "البرازيل",
          Hungary: "المجر",
          Japan: "اليابان",
          Libya: "ليبيا",
          Nepal: "نيبال",
          Andorra: "أندورا",
          Moldova: "مولدوفا",
          Kenya: "كينيا",
          Albania: "ألبانيا",
          India: "الهند",
          Taiwan: "تايوان",
          Peru: "بيرو",
          Zambia: "زامبيا",
          Bangladesh: "بنغلاديش",
          Czechia: "التشيك",
          DRCongo: "جمهورية الكونغو الديمقراطية",
          Spain: "إسبانيا",
          Afghanistan: "أفغانستان",
          Angola: "أنغولا",
          Botswana: "بوتسوانا",
          BurkinaFaso: "بوركينا فاسو",
          Burundi: "بوروندي",
          CapeVerde: "الرأس الأخضر",
          Cameroon: "الكاميرون",
          CentralAfricanRepublic: "جمهورية أفريقيا الوسطى",
          Chad: "تشاد",
          Comoros: "جزر القمر",
          CongoRepublic: "جمهورية الكونغو",
          CoteDIvoire: "ساحل العاج",
          Djibouti: "جيبوتي",
          EquatorialGuinea: "غينيا الاستوائية",
          Eritrea: "إريتريا",
          Eswatini: "إسواتيني",
          Ethiopia: "إثيوبيا",
          Gabon: "الغابون",
          Gambia: "غامبيا",
          Ghana: "غانا",
          Guinea: "غينيا",
          GuineaBissau: "غينيا بيساو",
          Lesotho: "ليسوتو",
          Liberia: "ليبيريا",
          Madagascar: "مدغشقر",
          Malawi: "مالاوي",
          Mali: "مالي",
          Mauritania: "موريتانيا",
          Mauritius: "موريشيوس",
          Mozambique: "موزمبيق",
          Namibia: "ناميبيا",
          Niger: "النيجر",
          Rwanda: "رواندا",
          SaoTomeAndPrincipe: "ساو تومي وبرينسيبي",
          Senegal: "السنغال",
          Seychelles: "سيشل",
          SierraLeone: "سيراليون",
          Somalia: "الصومال",
          SouthSudan: "جنوب السودان",
          Sudan: "السودان",
          Tanzania: "تنزانيا",
          Tunisia: "تونس",
          Uganda: "أوغندا",
          Zimbabwe: "زيمبابوي",
          Bhutan: "بوتان",
          Iran: "إيران",
          Iraq: "العراق",
          Jordan: "الأردن",
          Kuwait: "الكويت",
          Kyrgyzstan: "قيرغيزستان",
          Laos: "لاوس",
          Lebanon: "لبنان",
          Maldives: "المالديف",
          Mongolia: "منغوليا",
          Myanmar: "ميانمار",
          NorthKorea: "كوريا الشمالية",
          Oman: "عُمان",
          Palestine: "فلسطين",
          Syria: "سوريا",
          Tajikistan: "طاجيكستان",
          Turkmenistan: "تركمانستان",
          Uzbekistan: "أوزبكستان",
          Yemen: "اليمن",
          Belarus: "بيلاروس",
          Kosovo: "كوسوفو",
          SanMarino: "سان مارينو",
          AntiguaAndBarbuda: "أنتيغوا وباربودا",
          Barbados: "باربادوس",
          Belize: "بيليز",
          Bolivia: "بوليفيا",
          Cuba: "كوبا",
          Dominica: "دومينيكا",
          DominicanRepublic: "الجمهورية الدومينيكية",
          ElSalvador: "السلفادور",
          Grenada: "غرينادا",
          Guyana: "غيانا",
          Haiti: "هايتي",
          Honduras: "هندوراس",
          Nicaragua: "نيكاراغوا",
          Paraguay: "باراجواي",
          SaintKittsAndNevis: "سانت كيتس ونيفيس",
          SaintLucia: "سانت لوسيا",
          SaintVincentAndTheGrenadines: "سانت فنسنت والغرينادين",
          Suriname: "سورينام",
          TrinidadAndTobago: "ترينيداد وتوباغو",
          youListenedTo: "لقد استمعت إلى",
          from: "من",
          by: "بواسطة",
          youWereCharged: "تم تحصيل رسوم منك",
          youVisited: "لقد زرت",
          youHadA: "كان لديك",
          minute: "دقيقة",
          hour: "ساعة",
          calendarEvent: "حدث تقويمي",
          youReceivedAnEmailFrom: "لقد استلمت بريدًا إلكترونيًا من",
          youSentAnEmailTo: "لقد أرسلت بريدًا إلكترونيًا إلى",
          youReadAnEmailFrom: "لقد قرأت بريدًا إلكترونيًا من",
          youReceivedNewDataFromOura: "لقد تلقيت بيانات جديدة من Oura",
        }
      },
      ru: {
        translation: {
          continueWithGoogle: "Продолжить с Google",
          continueWithEmail: "Продолжить с электронной почтой",
          byContinuingYouAgreeToThe: "Продолжая, вы соглашаетесь с",
          termsOfService: "Условиями использования",
          and: "и",
          privacyPolicy: "Политикой конфиденциальности",
          emailPlaceholder: "ваш@почта.com",
          goBack: "Назад",
          thatDoesntMatchWhatWeSent: "Это не соответствует тому, что мы отправили",
          weJustSentACodeTo: "Мы только что отправили код на",
          enterItHereToContinue: "Введите его здесь для продолжения",
          wrongEmail: "Неверный email",
          confirm: "Подтвердить",
          welcomeToVALT: "Добро пожаловать в VALT",
          welcomeToVALTMarketplace: "Добро пожаловать на маркетплейс VALT",
          marketplaceDataKeyDisclosure: "Вот ваш ключ данных. Он позволяет получить доступ к приобретённым данным, поэтому скопируйте его и сохраните перед продолжением и никогда не делитесь им. Это показывается только при регистрации. Если ключ потерян, данные, приобретённые с этим аккаунтом, будут безвозвратны.",
          dataKeyDisclosure: "Вот ваш ключ данных. Он может понадобиться для связывания устройств и восстановления данных, поэтому скопируйте его и сохраните перед продолжением и никогда не делитесь им. Это показывается только при регистрации. Если ключ потерян, данные этого аккаунта будут безвозвратны.",
          copy: "Копировать",
          copied: "Скопировано",
          ok: "ОК",
          withThisAppYoullBeAbleTo: "С этим приложением вы сможете",
          secure: "защитить",
          interactWith: "взаимодействовать с",
          monetize: "монетизировать",
          yourData: "ваши данные",
          howVALTSecuresData: "VALT позволяет сохранять данные, которые вы создаёте в приложениях и сервисах, такие как данные GPS, электронная почта, история браузера, данные о покупках и многое другое",
          howVALTAllowsInteraction: "Наш ИИ-ассистент и личная временная шкала данных позволяют вам возвращаться к созданным данным и получать инсайты о себе, ранее доступные только Google и цифровым маркетологам",
          howVALTMonetizesData: "При достаточном количестве пользователей наш рынок персональных данных вскоре позволит вам впервые продавать свои данные. Пригласите друзей и помогите запустить это движение уже сегодня",
          next: "Далее",
          back: "Назад",
          connectdVPN: "Подключить dVPN",
          connectdVPNInstructions: "Подключитесь к dVPN через панель навигации внизу экрана",
          connectDataSources: "Подключить источники данных",
          connectDataSourcesInstructions: "Добавьте новые источники данных через меню настроек в верхнем левом углу",
          gotIt: "Понял",
          noDataCollectedFor: "Данных не собрано для",
          tapForFastestConnections: "Нажмите для самых быстрых соединений",
          gettingData: "Получение данных",
          secureMoreDataSources: "Подключите больше защищённых источников данных",
          upgradeVALT: "Обновите до VALT+",
          changeDate: "Изменить дату",
          account: "Аккаунт",
          connectedApps: "Подключённые приложения",
          supportAndFAQ: "Поддержка и FAQ",
          logOut: "Выйти",
          copywright: "© 2025 HashCash, Inc",
          EULA: "Лицензионное соглашение",
          timeline: "Хронология",
          chat: "Чат",
          moreInfo: "Подробнее",
          download: "Скачать",
          upload: "Загрузить",
          youAreCurrentlyConnectedTo: "Вы сейчас подключены к",
          updateConnection: "Обновить соединение",
          hey: "Привет",
          welcomeBack: "С возвращением",
          iveDetected: "Я обнаружил",
          leaksAssociatedWithYourData: "утечки, связанные с вашими данными",
          youreNotConnectedToOurdVPNHeresAFastConnectionForYouIn: "Вы не подключены к нашему dVPN. Вот быстрое соединение для вас в",
          yourAccount: "Ваш аккаунт",
          firstName: "Имя",
          lastName: "Фамилия",
          email: "Эл. почта",
          phone: "Телефон",
          notProvided: "Не предоставлено",
          billing: "Биллинг",
          deleteAccount: "Удалить аккаунт",
          areYouSure: "Вы уверены?",
          yes: "Да",
          waitNevermind: "Подождите, забудьте",
          version: "Версия",
          takeControlOfYourPersonalDataTodayWithVALTPlus: "Возьмите под контроль свои личные данные сегодня с VALT+",
          annualPlan: "Годовой план",
          currentPlan: "Текущий план",
          whatsIncluded: "Что включено",
          decentralizedVPNForSecureBrowsing: "Децентрализованный VPN для безопасного просмотра",
          dVPNForUnlimitedDevices: "dVPN для неограниченного числа устройств",
          advancedBackupTools: "Продвинутые инструменты резервного копирования",
          fullPersonalDataTimelineIntegratedWithDozensOfApps: "Полная временная шкала личных данных, интегрированная с десятками приложений",
          dataCleanup: "Постоянная очистка данных",
          VALTWillForceBrokers: "VALT заставит брокеров данных удалить вашу личную информацию для сокращения спам-звонков и писем",
          monthlyPlan: "Месячный план",
          twoYearPlan: "Двухгодичный план",
          billedEveryTwoYears: "Оплата каждые два года",
          billedAnnually: "Оплата ежегодно",
          select: "Выбрать",
          selectPaymentMethod: "Выберите способ оплаты",
          creditOrDebitCard: "Кредитная или дебетовая карта",
          introPriceDisclosure: "Начальная цена действительна для первого срока подписки. Экономия, предоставленная по этой цене, сравнивается с текущей ценой продления",
          planUpdated: "План обновлён",
          somethingWentWrong: "Что-то пошло не так. Пожалуйста, попробуйте позже",
          updatePlan: "Обновить план",
          welcomeToVALTPlus: "Добро пожаловать в VALT+",
          weNeedToCollectDetailsToCleanupData: "Нам нужно собрать несколько деталей, чтобы начать очистку ваших данных",
          pleaseEnterYourFirstName: "Пожалуйста, введите ваше имя",
          pleaseEnterYourLastName: "Пожалуйста, введите вашу фамилию",
          pleaseEnterYourPostalCode: "Если вы в США, введите ваш почтовый индекс — иначе введите 0",
          pleaseEnterYourBirthday: "Пожалуйста, введите вашу дату рождения",
          pleaseEnterYourPhoneNumber: "Пожалуйста, введите ваш номер телефона",
          aboutVALTPlus: "О VALT+",
          aboutVALTPlusDetails: "VALT предлагает набор инструментов для управления вашими данными. Подписываясь сегодня, вы помогаете нам создать рынок персональных данных, где вы сможете получать оплату за свои данные. VALT никогда не получает доступ к данным, которые вы собираете в приложении VALT.",
          planSummary: "Итог плана",
          unitedStates: "Соединённые Штаты",
          salesTax: "Налог с продаж",
          total: "Итого",
          taxCountry: "Страна налогообложения",
          oneyearplan: "План на 1 год",
          twoyearplan: "План на 2 года",
          monthlyplan: "Месячный план",
          unlimiteddVPNAccess: "Неограниченный доступ к dVPN",
          fullPersonalDataTimeline: "Полная временная шкала личных данных",
          constantDataCleanup: "Очистка данных 24/7",
          serverIsUnavailable: "Сервер недоступен",
          tapToConnect: "Нажмите для подключения",
          connecting: "Подключение",
          tapToDisconnect: "Нажмите для отключения",
          connected: "Подключено",
          returnToCurrentConnection: "Вернуться к текущему соединению",
          disconnecting: "Отключение",
          connectAccounts: "Подключить аккаунты",
          connectAccountsDisclosure: "Подключите свои аккаунты для защиты данных. Продолжая, вы соглашаетесь с политикой конфиденциальности и условиями использования",
          thisDataSourceIsOnlyAvailableOnMobileDevices: "Этот источник данных доступен только на мобильных устройствах",
          show: "Показать",
          bank: "Банк",
          onDashboard: "на панели управления",
          reauthorize: "Повторная авторизация",
          disconnect: "Отключить",
          downloadTheCromeExtensionFrom: "Скачайте расширение Chrome на компьютер с",
          andEnterTheFollowingCode: "и введите следующий код",
          linkDevice: "Связать устройство",
          toAccessYourDataFromThisDevice: "Чтобы получить доступ к данным с этого устройства, свяжите ваш аккаунт с вашим ключом данных",
          linkAccount: "Связать аккаунт",
          lostYourDataKey: "Потеряли ключ данных?",
          getNewKey: "Получить новый ключ",
          signOut: "Выйти",
          accessYourDataOnDesktop: "Чтобы получить доступ к данным на компьютере, свяжите ваш аккаунт с вашим ключом данных",
          dataKey: "Ключ данных",
          enterYourDataKey: "Введите ваш ключ данных",
          paste: "Вставить",
          cancel: "Отмена",
          continue: "Продолжить",
          confirmDevice: "Подтвердить устройство",
          weJustSentYouAnEmailToConfirmThisDevice: "Мы только что отправили вам письмо для подтверждения этого устройства",
          afterRespondingToEmail: "После ответа на письмо закройте и снова откройте приложение для доступа",
          checkingForAccess: "Проверка доступа",
          wrongDevice: "Неверное устройство",
          thisDeviceIsCurrentlyConnectedToAnotherVALTAccount: "Это устройство в данный момент связано с другим аккаунтом VALT",
          enterCode: "Введите код",
          weJustEmailedAccountReset: "Мы только что отправили вам код сброса аккаунта по электронной почте. Введите его здесь для продолжения",
          accountResetDisclosure: "Обратите внимание, что после сброса аккаунта все данные, собранные с предыдущим ключом данных, будут безвозвратно утрачены, и вам придётся снова войти во все устройства с новым ключом данных",
          resetAccount: "Сбросить аккаунт",
          theCodeYouProvidedWasIncorrect: "Введённый вами код неверен",
          resetDevice: "Сбросить устройство",
          VALTPlusDashboard: "Панель VALT+",
          initDataCleanupMessage: "Мы начинаем очищать ваши данные. Вернитесь скоро. Обратите внимание, что услуги очистки данных в настоящее время поддерживаются только в США",
          selectDisplayAccount: "Выберите отображаемый аккаунт",
          wereSendingYouToTheLoginPageForThisApp: "Мы перенаправляем вас на страницу входа для этого приложения",
          connectAppSuccess: "Подтверждено. Вернитесь в приложение VALT, чтобы изменения вступили в силу",
          save74: "(Сэкономьте 74%)",
          save66: "(Сэкономьте 66%)",
          save68: "(Сэкономьте 68%)",
          save60: "(Сэкономьте 60%)",
          save20: "(Сэкономьте 20%)",
          friend: "друг",
          youJustArrived: "Вы только что прибыли",
          youArrived: "Вы прибыли",
          ago: "назад",
          oneSecond: "1 секунда",
          oneMinute: "1 минута",
          oneHour: "1 час",
          seconds: "секунды",
          minutes: "минуты",
          hours: "часы",
          youVisitedThisLocation: "Вы посетили это место",
          youSpent: "Вы потратили",
          near: "рядом с",
          thisLocation: "этим местом",
          youTravelledFor: "Вы путешествовали в течение",
          towards: "в направлении",
          earth: "где-то на Земле",
          at: "на",
          Georgia: "Грузия",
          Colombia: "Колумбия",
          SriLanka: "Шри-Ланка",
          UnitedArabEmirates: "Объединённые Арабские Эмираты",
          Canada: "Канада",
          UnitedStates: "Соединённые Штаты",
          Slovenia: "Словения",
          Belgium: "Бельгия",
          Bahamas: "Багамы",
          NorthMacedonia: "Северная Македония",
          Ecuador: "Эквадор",
          Malaysia: "Малайзия",
          Montenegro: "Черногория",
          Azerbaijan: "Азербайджан",
          Jamaica: "Ямайка",
          Kazakhstan: "Казахстан",
          Latvia: "Латвия",
          Argentina: "Аргентина",
          Russia: "Россия",
          TheNetherlands: "Нидерланды",
          Venezuela: "Венесуэла",
          Germany: "Германия",
          Turkey: "Турция",
          Vietnam: "Вьетнам",
          Luxembourg: "Люксембург",
          Thailand: "Таиланд",
          HongKong: "Гонконг",
          Romania: "Румыния",
          Monaco: "Монако",
          Greece: "Греция",
          IsleofMan: "Остров Мэн",
          Pakistan: "Пакистан",
          Uruguay: "Уругвай",
          Armenia: "Армения",
          Liechtenstein: "Лихтенштейн",
          Qatar: "Катар",
          Switzerland: "Швейцария",
          Egypt: "Египет",
          Bulgaria: "Болгария",
          Cyprus: "Кипр",
          Lithuania: "Литва",
          Panama: "Панама",
          Croatia: "Хорватия",
          Slovakia: "Словакия",
          Sweden: "Швеция",
          Philippines: "Филиппины",
          Macao: "Макао",
          Austria: "Австрия",
          Portugal: "Португалия",
          Algeria: "Алжир",
          Australia: "Австралия",
          SouthAfrica: "Южная Африка",
          NewZealand: "Новая Зеландия",
          SaudiArabia: "Саудовская Аравия",
          Bahrain: "Бахрейн",
          China: "Китай",
          Estonia: "Эстония",
          Morocco: "Марокко",
          Cambodia: "Камбоджа",
          SouthKorea: "Южная Корея",
          Singapore: "Сингапур",
          Ireland: "Ирландия",
          Iceland: "Исландия",
          Türkiye: "Турция",
          Guatemala: "Гватемала",
          PuertoRico: "Пуэрто-Рико",
          Finland: "Финляндия",
          Greenland: "Гренландия",
          Indonesia: "Индонезия",
          UnitedKingdom: "Великобритания",
          Serbia: "Сербия",
          Nigeria: "Нигерия",
          BosniaandHerzegovina: "Босния и Герцеговина",
          Poland: "Польша",
          Netherlands: "Нидерланды",
          Italy: "Италия",
          France: "Франция",
          Denmark: "Дания",
          Israel: "Израиль",
          Mexico: "Мексика",
          Norway: "Норвегия",
          Chile: "Чили",
          Malta: "Мальта",
          Ukraine: "Украина",
          Brazil: "Бразилия",
          Hungary: "Венгрия",
          Japan: "Япония",
          Libya: "Ливия",
          Nepal: "Непал",
          Andorra: "Андорра",
          Moldova: "Молдова",
          Kenya: "Кения",
          Albania: "Албания",
          India: "Индия",
          Taiwan: "Тайвань",
          Peru: "Перу",
          Zambia: "Замбия",
          Bangladesh: "Бангладеш",
          Czechia: "Чехия",
          DRCongo: "ДР Конго",
          Spain: "Испания",
          Afghanistan: "Афганистан",
          Angola: "Ангола",
          Botswana: "Ботсвана",
          BurkinaFaso: "Буркина-Фасо",
          Burundi: "Бурунди",
          CapeVerde: "Кабо-Верде",
          Cameroon: "Камерун",
          CentralAfricanRepublic: "ЦентральноафриканскаяРеспублика",
          Chad: "Чад",
          Comoros: "Коморы",
          CongoRepublic: "РеспубликаКонго",
          CoteDIvoire: "Кот-д’Ивуар",
          Djibouti: "Джибути",
          EquatorialGuinea: "ЭкваториальнаяГвинея",
          Eritrea: "Эритрея",
          Eswatini: "Эсватини",
          Ethiopia: "Эфиопия",
          Gabon: "Габон",
          Gambia: "Гамбия",
          Ghana: "Гана",
          Guinea: "Гвинея",
          GuineaBissau: "Гвинея-Бисау",
          Lesotho: "Лесото",
          Liberia: "Либерия",
          Madagascar: "Мадагаскар",
          Malawi: "Малави",
          Mali: "Мали",
          Mauritania: "Мавритания",
          Mauritius: "Маврикий",
          Mozambique: "Мозамбик",
          Namibia: "Намибия",
          Niger: "Нигер",
          Rwanda: "Руанда",
          SaoTomeAndPrincipe: "Сан-ТомеИПринсипи",
          Senegal: "Сенегал",
          Seychelles: "СейшельскиеОстрова",
          SierraLeone: "Сьерра-Леоне",
          Somalia: "Сомали",
          SouthSudan: "ЮжныйСудан",
          Sudan: "Судан",
          Tanzania: "Танзания",
          Tunisia: "Тунис",
          Uganda: "Уганда",
          Zimbabwe: "Зимбабве",
          Bhutan: "Бутан",
          Iran: "Иран",
          Iraq: "Ирак",
          Jordan: "Иордания",
          Kuwait: "Кувейт",
          Kyrgyzstan: "Киргизия",
          Laos: "Лаос",
          Lebanon: "Ливан",
          Maldives: "Мальдивы",
          Mongolia: "Монголия",
          Myanmar: "Мьянма",
          NorthKorea: "СевернаяКорея",
          Oman: "Оман",
          Palestine: "Палестина",
          Syria: "Сирия",
          Tajikistan: "Таджикистан",
          Turkmenistan: "Туркмения",
          Uzbekistan: "Узбекистан",
          Yemen: "Йемен",
          Belarus: "Беларусь",
          Kosovo: "Косово",
          SanMarino: "Сан-Марино",
          AntiguaAndBarbuda: "АнтигуаИБарбуда",
          Barbados: "Барбадос",
          Belize: "Белиз",
          Bolivia: "Боливия",
          Cuba: "Куба",
          Dominica: "Доминика",
          DominicanRepublic: "ДоминиканскаяРеспублика",
          ElSalvador: "Сальвадор",
          Grenada: "Гренада",
          Guyana: "Гайана",
          Haiti: "Гаити",
          Honduras: "Гондурас",
          Nicaragua: "Никарагуа",
          Paraguay: "Парагвай",
          SaintKittsAndNevis: "Сент-КитсИНевис",
          SaintLucia: "Сент-Люсия",
          SaintVincentAndTheGrenadines: "Сент-ВинсентИГренадины",
          Suriname: "Суринам",
          TrinidadAndTobago: "ТринидадИТобаго",
          youListenedTo: "Вы слушали",
          from: "из",
          by: "от",
          youWereCharged: "С вас сняли",
          youVisited: "Вы посетили",
          youHadA: "У вас был",
          minute: "минута",
          hour: "час",
          calendarEvent: "календарное событие",
          youReceivedAnEmailFrom: "Вы получили письмо от",
          youSentAnEmailTo: "Вы отправили электронное письмо на",
          youReadAnEmailFrom: "Вы прочитали электронное письмо от",
          youReceivedNewDataFromOura: "Вы получили новые данные от Oura",
        }
      },
      pt: {
        translation: {
          continueWithGoogle: "Continuar com o Google",
          continueWithEmail: "Continuar com o Email",
          byContinuingYouAgreeToThe: "Ao continuar, você concorda com os",
          termsOfService: "Termos de Serviço",
          and: "e",
          privacyPolicy: "Política de Privacidade",
          emailPlaceholder: "seu@email.com",
          goBack: "Voltar",
          thatDoesntMatchWhatWeSent: "Isso não corresponde ao que enviamos",
          weJustSentACodeTo: "Acabamos de enviar um código para",
          enterItHereToContinue: "Digite aqui para continuar",
          wrongEmail: "Email incorreto",
          confirm: "Confirmar",
          welcomeToVALT: "Bem-vindo ao VALT",
          welcomeToVALTMarketplace: "Bem-vindo ao Mercado VALT",
          marketplaceDataKeyDisclosure: "Esta é a sua Chave de Dados. Ela permite que você acesse os dados que compra, então copie/cole-a em algum lugar antes de prosseguir e nunca a compartilhe. Isso só aparece no cadastro. Se perdida, os dados adquiridos por essa conta serão irrecuperáveis",
          dataKeyDisclosure: "Esta é a sua Chave de Dados. Você pode precisar dela para vincular dispositivos e recuperar dados, então copie/cole-a em algum lugar antes de prosseguir e nunca a compartilhe. Isso só aparece no cadastro. Se perdida, os dados desta conta serão irrecuperáveis",
          copy: "Copiar",
          copied: "Copiado",
          ok: "Ok",
          withThisAppYoullBeAbleTo: "Com este aplicativo, você poderá",
          secure: "Proteger",
          interactWith: "Interagir com",
          monetize: "Monetizar",
          yourData: "seus dados",
          howVALTSecuresData: "O VALT permite capturar os dados que você cria em seus aplicativos e serviços, como dados de GPS, email, histórico de navegação, dados de compra e muito mais",
          howVALTAllowsInteraction: "Nosso assistente de IA e linha do tempo de dados pessoais permitem que você consulte os dados que criou e obtenha insights sobre si mesmo, anteriormente acessíveis apenas ao Google e aos profissionais de marketing digital",
          howVALTMonetizesData: "Com usuários suficientes, nosso Mercado de Dados Pessoais em breve permitirá que você venda seus dados pela primeira vez. Indique seus amigos e ajude a impulsionar esse movimento hoje",
          next: "Próximo",
          back: "Voltar",
          connectdVPN: "Conectar ao dVPN",
          connectdVPNInstructions: "Conecte ao dVPN pela barra de navegação na parte inferior da tela",
          connectDataSources: "Conectar Fontes de Dados",
          connectDataSourcesInstructions: "Adicione novas fontes de dados acessando o menu de configurações no canto superior esquerdo",
          gotIt: "Entendi",
          noDataCollectedFor: "Nenhum dado coletado para",
          tapForFastestConnections: "Toque para as conexões mais rápidas",
          gettingData: "Obtendo dados",
          secureMoreDataSources: "Conecte mais fontes de dados seguras",
          upgradeVALT: "Atualize para VALT+",
          changeDate: "Alterar Data",
          account: "Conta",
          connectedApps: "Aplicativos Conectados",
          supportAndFAQ: "Suporte & FAQ",
          logOut: "Sair",
          copywright: "© 2025 HashCash, Inc",
          EULA: "EULA",
          timeline: "Linha do Tempo",
          chat: "Chat",
          moreInfo: "Mais Informações",
          download: "Baixar",
          upload: "Enviar",
          youAreCurrentlyConnectedTo: "Você está atualmente conectado a",
          updateConnection: "Atualizar Conexão",
          hey: "Ei",
          welcomeBack: "Bem-vindo de volta",
          iveDetected: "Detectei",
          leaksAssociatedWithYourData: "vazamentos associados aos seus dados",
          youreNotConnectedToOurdVPNHeresAFastConnectionForYouIn: "Você não está conectado ao nosso dVPN. Aqui está uma conexão rápida para você em",
          yourAccount: "Sua Conta",
          firstName: "Nome",
          lastName: "Sobrenome",
          email: "Email",
          phone: "Telefone",
          notProvided: "Não fornecido",
          billing: "Faturamento",
          deleteAccount: "Excluir Conta",
          areYouSure: "Você tem certeza?",
          yes: "Sim",
          waitNevermind: "Espere, esqueça",
          version: "Versão",
          takeControlOfYourPersonalDataTodayWithVALTPlus: "Assuma o controle dos seus dados pessoais hoje com o VALT+",
          annualPlan: "Plano Anual",
          currentPlan: "Plano Atual",
          whatsIncluded: "O que está incluído",
          decentralizedVPNForSecureBrowsing: "VPN descentralizado para navegação segura",
          dVPNForUnlimitedDevices: "dVPN para dispositivos ilimitados",
          advancedBackupTools: "Ferramentas avançadas de backup",
          fullPersonalDataTimelineIntegratedWithDozensOfApps: "Linha do Tempo Completa de Dados Pessoais Integrada com Dezenas de Aplicativos",
          dataCleanup: "Limpeza Contínua de Dados",
          VALTWillForceBrokers: "O VALT forçará os corretores de dados a apagar suas informações pessoais para reduzir chamadas e emails de spam",
          monthlyPlan: "Plano Mensal",
          twoYearPlan: "Plano de 2 Anos",
          billedEveryTwoYears: "Cobrado a cada 2 anos",
          billedAnnually: "Cobrado anualmente",
          select: "Selecionar",
          selectPaymentMethod: "Selecionar Método de Pagamento",
          creditOrDebitCard: "Cartão de Crédito ou Débito",
          introPriceDisclosure: "O preço promocional é válido para o primeiro período da sua assinatura. As economias concedidas pelo preço promocional são comparadas com o preço de renovação atual",
          planUpdated: "Plano Atualizado",
          somethingWentWrong: "Algo deu errado. Por favor, tente novamente mais tarde",
          updatePlan: "Atualizar Plano",
          welcomeToVALTPlus: "Bem-vindo ao VALT+",
          weNeedToCollectDetailsToCleanupData: "Precisamos coletar alguns detalhes para começar a limpar seus dados",
          pleaseEnterYourFirstName: "Por favor, insira seu primeiro nome",
          pleaseEnterYourLastName: "Por favor, insira seu sobrenome",
          pleaseEnterYourPostalCode: "Se você estiver nos EUA, insira seu CEP - caso contrário, insira 0",
          pleaseEnterYourBirthday: "Por favor, insira sua data de nascimento",
          pleaseEnterYourPhoneNumber: "Por favor, insira seu telefone",
          aboutVALTPlus: "Sobre o VALT+",
          aboutVALTPlusDetails: "O VALT oferece um conjunto de ferramentas para ajudar a gerenciar seus dados. Ao se inscrever hoje, você nos ajuda a construir nosso Mercado de Dados Pessoais, onde você pode ser pago pelos seus dados. O VALT nunca tem acesso aos dados coletados no aplicativo VALT.",
          planSummary: "Resumo do Plano",
          unitedStates: "Estados Unidos",
          salesTax: "Imposto sobre Vendas",
          total: "Total",
          taxCountry: "País do Imposto",
          oneyearplan: "Plano de 1 Ano",
          twoyearplan: "Plano de 2 Anos",
          monthlyplan: "Plano Mensal",
          unlimiteddVPNAccess: "Acesso Ilimitado ao dVPN",
          fullPersonalDataTimeline: "Linha do Tempo Completa de Dados Pessoais",
          constantDataCleanup: "Limpeza de Dados 24/7",
          serverIsUnavailable: "Servidor Indisponível",
          tapToConnect: "Toque para Conectar",
          connecting: "Conectando",
          tapToDisconnect: "Toque para Desconectar",
          connected: "Conectado",
          returnToCurrentConnection: "Retorne à Conexão Atual",
          disconnecting: "Desconectando",
          connectAccounts: "Conectar Contas",
          connectAccountsDisclosure: "Conecte suas contas para proteger seus dados. Ao continuar, você concorda com a política de privacidade e os termos de serviço.",
          thisDataSourceIsOnlyAvailableOnMobileDevices: "Esta fonte de dados está disponível apenas em dispositivos móveis",
          show: "Mostrar",
          bank: "Banco",
          onDashboard: "no Painel",
          reauthorize: "Reautorizar",
          disconnect: "Desconectar",
          downloadTheCromeExtensionFrom: "Baixe a extensão do Chrome no seu computador de",
          andEnterTheFollowingCode: "e insira o seguinte código",
          linkDevice: "Vincular Dispositivo",
          toAccessYourDataFromThisDevice: "Para acessar seus dados deste dispositivo, vincule sua conta com sua Chave de Dados",
          linkAccount: "Vincular Conta",
          lostYourDataKey: "Perdeu sua Chave de Dados?",
          getNewKey: "Obter Nova Chave",
          signOut: "Sair",
          accessYourDataOnDesktop: "Para acessar seus dados no desktop, vincule sua conta com sua Chave de Dados",
          dataKey: "Chave de Dados",
          enterYourDataKey: "Digite sua Chave de Dados",
          paste: "Colar",
          cancel: "Cancelar",
          continue: "Continuar",
          confirmDevice: "Confirmar Dispositivo",
          weJustSentYouAnEmailToConfirmThisDevice: "Acabamos de enviar um email para confirmar este dispositivo",
          afterRespondingToEmail: "Após responder ao email, feche e reabra o aplicativo para acessar",
          checkingForAccess: "Verificando acesso",
          wrongDevice: "Dispositivo Incorreto",
          thisDeviceIsCurrentlyConnectedToAnotherVALTAccount: "Este dispositivo está atualmente vinculado a outra conta VALT",
          enterCode: "Digite o código",
          weJustEmailedAccountReset: "Acabamos de enviar um código de redefinição de conta por email. Digite-o aqui para continuar",
          accountResetDisclosure: "Observe que, após redefinir sua conta, todos os dados coletados com a chave de dados anterior não poderão ser recuperados, e você precisará fazer login novamente em todos os dispositivos com a nova chave de dados",
          resetAccount: "Redefinir Conta",
          theCodeYouProvidedWasIncorrect: "O código fornecido está incorreto",
          resetDevice: "Redefinir Dispositivo",
          VALTPlusDashboard: "Painel do VALT+",
          initDataCleanupMessage: "Estamos começando a limpar seus dados. Volte em breve. Observe que os serviços de limpeza de dados são suportados apenas nos EUA",
          selectDisplayAccount: "Selecionar Conta de Exibição",
          wereSendingYouToTheLoginPageForThisApp: "Estamos enviando você para a página de login deste aplicativo",
          connectAppSuccess: "Confirmado. Retorne ao seu aplicativo VALT para que as alterações entrem em vigor",
          save74: "(Economize 74%)",
          save66: "(Economize 66%)",
          save68: "(Economize 68%)",
          save60: "(Economize 60%)",
          save20: "(Economize 20%)",
          friend: "amigo",
          youJustArrived: "Você acabou de chegar",
          youArrived: "Você chegou",
          ago: "atrás",
          oneSecond: "1 segundo",
          oneMinute: "1 minuto",
          oneHour: "1 hora",
          seconds: "segundos",
          minutes: "minutos",
          hours: "horas",
          youVisitedThisLocation: "Você visitou este local",
          youSpent: "Você passou",
          near: "perto de",
          thisLocation: "este local",
          youTravelledFor: "Você viajou por",
          towards: "em direção a",
          earth: "Em algum lugar na Terra",
          at: "em",
          Georgia: "Geórgia",
          Colombia: "Colômbia",
          SriLanka: "Sri Lanka",
          UnitedArabEmirates: "Emirados Árabes Unidos",
          Canada: "Canadá",
          UnitedStates: "Estados Unidos",
          Slovenia: "Eslovênia",
          Belgium: "Bélgica",
          Bahamas: "Bahamas",
          NorthMacedonia: "Macedónia do Norte",
          Ecuador: "Equador",
          Malaysia: "Malásia",
          Montenegro: "Montenegro",
          Azerbaijan: "Azerbaijão",
          Jamaica: "Jamaica",
          Kazakhstan: "Cazaquistão",
          Latvia: "Letônia",
          Argentina: "Argentina",
          Russia: "Rússia",
          TheNetherlands: "Países Baixos",
          Venezuela: "Venezuela",
          Germany: "Alemanha",
          Turkey: "Turquia",
          Vietnam: "Vietnã",
          Luxembourg: "Luxemburgo",
          Thailand: "Tailândia",
          HongKong: "Hong Kong",
          Romania: "Romênia",
          Monaco: "Mônaco",
          Greece: "Grécia",
          IsleofMan: "Ilha de Man",
          Pakistan: "Paquistão",
          Uruguay: "Uruguai",
          Armenia: "Armênia",
          Liechtenstein: "Liechtenstein",
          Qatar: "Catar",
          Switzerland: "Suíça",
          Egypt: "Egito",
          Bulgaria: "Bulgária",
          Cyprus: "Chipre",
          Lithuania: "Lituânia",
          Panama: "Panamá",
          Croatia: "Croácia",
          Slovakia: "Eslováquia",
          Sweden: "Suécia",
          Philippines: "Filipinas",
          Macao: "Macau",
          Austria: "Áustria",
          Portugal: "Portugal",
          Algeria: "Argélia",
          Australia: "Austrália",
          SouthAfrica: "África do Sul",
          NewZealand: "Nova Zelândia",
          SaudiArabia: "Arábia Saudita",
          Bahrain: "Bahrein",
          China: "China",
          Estonia: "Estônia",
          Morocco: "Marrocos",
          Cambodia: "Camboja",
          SouthKorea: "Coreia do Sul",
          Singapore: "Singapura",
          Ireland: "Irlanda",
          Iceland: "Islândia",
          Türkiye: "Turquia",
          Guatemala: "Guatemala",
          PuertoRico: "Porto Rico",
          Finland: "Finlândia",
          Greenland: "Groenlândia",
          Indonesia: "Indonésia",
          UnitedKingdom: "Reino Unido",
          Serbia: "Sérvia",
          Nigeria: "Nigéria",
          BosniaandHerzegovina: "Bósnia e Herzegovina",
          Poland: "Polônia",
          Netherlands: "Países Baixos",
          Italy: "Itália",
          France: "França",
          Denmark: "Dinamarca",
          Israel: "Israel",
          Mexico: "México",
          Norway: "Noruega",
          Chile: "Chile",
          Malta: "Malta",
          Ukraine: "Ucrânia",
          Brazil: "Brasil",
          Hungary: "Hungria",
          Japan: "Japão",
          Libya: "Líbia",
          Nepal: "Nepal",
          Andorra: "Andorra",
          Moldova: "Moldávia",
          Kenya: "Quênia",
          Albania: "Albânia",
          India: "Índia",
          Taiwan: "Taiwan",
          Peru: "Peru",
          Zambia: "Zâmbia",
          Bangladesh: "Bangladesh",
          Czechia: "Chéquia",
          DRCongo: "Congo (RDC)",
          Spain: "Espanha",
          Afghanistan: "Afeganistão",
          Angola: "Angola",
          Botswana: "Botsuana",
          BurkinaFaso: "Burkina Faso",
          Burundi: "Burundi",
          CapeVerde: "Cabo Verde",
          Cameroon: "Camarões",
          CentralAfricanRepublic: "República Centro-Africana",
          Chad: "Chade",
          Comoros: "Comores",
          CongoRepublic: "República do Congo",
          CoteDIvoire: "Costa do Marfim",
          Djibouti: "Djibuti",
          EquatorialGuinea: "Guiné Equatorial",
          Eritrea: "Eritreia",
          Eswatini: "Essuatíni",
          Ethiopia: "Etiópia",
          Gabon: "Gabão",
          Gambia: "Gâmbia",
          Ghana: "Gana",
          Guinea: "Guiné",
          GuineaBissau: "Guiné-Bissau",
          Lesotho: "Lesoto",
          Liberia: "Libéria",
          Madagascar: "Madagáscar",
          Malawi: "Malawi",
          Mali: "Mali",
          Mauritania: "Mauritânia",
          Mauritius: "Maurício",
          Mozambique: "Moçambique",
          Namibia: "Namíbia",
          Niger: "Níger",
          Rwanda: "Ruanda",
          SaoTomeAndPrincipe: "São Tomé e Príncipe",
          Senegal: "Senegal",
          Seychelles: "Seicheles",
          SierraLeone: "Serra Leoa",
          Somalia: "Somália",
          SouthSudan: "Sudão do Sul",
          Sudan: "Sudão",
          Tanzania: "Tanzânia",
          Tunisia: "Tunísia",
          Uganda: "Uganda",
          Zimbabwe: "Zimbábue",
          Bhutan: "Butão",
          Iran: "Irã",
          Iraq: "Iraque",
          Jordan: "Jordânia",
          Kuwait: "Kuwait",
          Kyrgyzstan: "Quirguistão",
          Laos: "Laos",
          Lebanon: "Líbano",
          Maldives: "Maldivas",
          Mongolia: "Mongólia",
          Myanmar: "Mianmar",
          NorthKorea: "Coreia do Norte",
          Oman: "Omã",
          Palestine: "Palestina",
          Syria: "Síria",
          Tajikistan: "Tajiquistão",
          Turkmenistan: "Turcomenistão",
          Uzbekistan: "Uzbequistão",
          Yemen: "Iémen",
          Belarus: "Bielorrússia",
          Kosovo: "Kosovo",
          SanMarino: "San Marino",
          AntiguaAndBarbuda: "Antígua e Barbuda",
          Barbados: "Barbados",
          Belize: "Belize",
          Bolivia: "Bolívia",
          Cuba: "Cuba",
          Dominica: "Dominica",
          DominicanRepublic: "República Dominicana",
          ElSalvador: "El Salvador",
          Grenada: "Granada",
          Guyana: "Guiana",
          Haiti: "Haiti",
          Honduras: "Honduras",
          Nicaragua: "Nicarágua",
          Paraguay: "Paraguai",
          SaintKittsAndNevis: "São Cristóvão e Névis",
          SaintLucia: "Santa Lúcia",
          SaintVincentAndTheGrenadines: "São Vicente e Granadinas",
          Suriname: "Suriname",
          TrinidadAndTobago: "Trinidad e Tobago",
          youListenedTo: "Você ouviu",
          from: "de",
          by: "por",
          youWereCharged: "Você foi cobrado",
          youVisited: "Você visitou",
          youHadA: "Você teve um",
          minute: "minuto",
          hour: "hora",
          calendarEvent: "evento de calendário",
          youReceivedAnEmailFrom: "Você recebeu um email de",
          youSentAnEmailTo: "Você enviou um email para",
          youReadAnEmailFrom: "Você leu um email de",
          youReceivedNewDataFromOura: "Você recebeu novos dados da Oura",
        }
      }            
    }
  });

export default i18n;
