import { userConstants, authConstants } from '../_constants';
import { getFlag } from '../_helpers';

export function user(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_STATE_SUCCESS:
    case authConstants.UPDATE_EMAIL_SUCCESS:
    case authConstants.UPDATE_NAME_SUCCESS:
    case authConstants.UPDATE_PIN_SUCCESS:
    case userConstants.UPDATE_PROFILE_PICTURE_URL_SUCCESS:
    case userConstants.UPDATE_APP_STORE_PURCHASE_STATUS_SUCCESS:
    case authConstants.SIGN_IN_SUCCESS:
    case authConstants.REGISTER_SUCCESS:
    case authConstants.RESET_ACCOUNT_SUCCESS:
    case userConstants.PLAID_REAUTH_SUCCESS:
      state.me = { ...state.me, ...action.data.state.me };
      if (action.data.hasOwnProperty('exists')) {
        state.me.isNewSignup = !action.data.exists
        if (action.data.hasOwnProperty('bcmk')) {
          // initial signup; expose BCMK once
          incognitoLocalStorage.setItem("BCMK", action.data.bcmk)
          state.me.bcmk = action.data.decrypted_bcmk;
        }
      }
      state.me.newDevice = action.data.hasOwnProperty('new_device');
      state.me.invalidBCMK = action.data.invalid_bcmk;
      state.me.polylinesGenerated = false;
      state.me.vpnSideBarHeightsAdded = false;
      state.me.sideBarHeightsAdded = false;
      state.apis = action.data.state.apis;
      state.prompts = action.data.state.prompts;
      state.apps = action.data.state.apps;
      state.leaks = { ...state.leaks, ...action.data.state.leaks };
      state.locations = { ...state.locations, ...action.data.state.locations };
      state.notifications = action.data.state.notifications;
      state.sales = { ...state.sales, ...action.data.state.sales };
      state.profile_details = action.data.state.profile_details;
      state.profile_detail_questions = action.data.state.profile_detail_questions;
      if (incognitoLocalStorage.getItem("BCMK") || window.location.href.includes("demo")) {
        state.posts = action.data.state.posts;
      } else {
        state.posts = {};
      }
      state.user_uploads = { ...state.user_uploads, ...action.data.state.user_uploads };
      state.source_requests = { ...state.source_requests, ...action.data.state.source_requests };
      state.weather_snapshots = action.data.state.weather_snapshots;
      state.offers = action.data.state.offers;
      state.buyers = action.data.state.buyers;
      if (action.data.state.user_agent) {
        state.user_agent = action.data.state.user_agent;
      }
      state.referral_leaders = action.data.state.referral_leaders;
      state.offer_responses = action.data.state.offer_responses;
      state.since_post_id = action.data.state.since_post_id;
      state.servers = action.data.state.servers;
      state.polylines = action.data.state.polylines;
      state.demo_state = action.data.state.demo_state;
      state.updated = action.data.state.updated;
      return state;

    case authConstants.BUSINESS_REGISTER_SUCCESS:
    case authConstants.BUSINESS_SIGN_IN_SUCCESS:
      return {};

    case userConstants.UPDATE_PROFILE_SUCCESS:
      if (action.data.state) {
        state.me = { ...state.me, ...action.data.state.me };
        state.apis = action.data.state.apis;
        state.prompts = action.data.state.prompts;
        state.apps = action.data.state.apps;
      } else if (action.data.me) {
        state.me = { ...state.me, ...action.data.me };
        state.me.newDevice = action.data.hasOwnProperty('new_device');
        state.me.invalidBCMK = action.data.invalid_bcmk;
        state.me.polylinesGenerated = false;
        state.me.vpnSideBarHeightsAdded = false;
        state.me.sideBarHeightsAdded = false;
      }
      if (action.data.hasOwnProperty('exists')) {
        state.me.isNewSignup = !action.data.exists
        if (action.data.hasOwnProperty('bcmk')) {
          // initial signup; expose BCMK once
          incognitoLocalStorage.setItem("BCMK", action.data.bcmk)
          state.me.bcmk = action.data.decrypted_bcmk;
        }
      }
      return state;

    case userConstants.DELETE_POST_SUCCESS:
      state.posts = { ...state.posts, ...action.data.posts };
      return state;

    case userConstants.GET_MORE_POSTS_SUCCESS:
      state.me = { ...state.me, ...action.data.me };
      state.me.current_dashboard_date = Object.values(action.data.posts)[0].localized_date_string;
      state.posts = { ...state.posts, ...action.data.posts };
      state.user_uploads = { ...state.user_uploads, ...action.data.user_uploads };
      state.locations = { ...state.locations, ...action.data.locations };
      state.me.polylinesGenerated = false;
      state.polylines = action.data.polylines;
      return state;

    case userConstants.GET_RECENT_LOCATION_DATA_SUCCESS:
      state.posts = { ...state.posts, ...action.data.posts };
      state.locations = { ...state.locations, ...action.data.locations };
      return state;

    case userConstants.JUMP_TO_DATE_SUCCESS:
      state.me.polylinesGenerated = false;
      state.me.sideBarHeightsAdded = false;
      state.posts = action.data.posts;
      state.user_uploads = action.data.user_uploads;
      state.locations = action.data.locations;
      state.me.last_post_id = null;
      state.me.polylinesGenerated = false;
      state.me.newDevice = action.data.hasOwnProperty('new_device');
      state.me.invalidBCMK = action.data.invalid_bcmk;
      state.polylines = action.data.polylines;
      return state;

    case userConstants.CREATE_CONNECTION_CREDENTIALS_SUCCESS:
      state.connection = action.data.result || state.connection;
      const server = action.data.server;
      let [flag, ccode] = getFlag(server);
      server.flag = flag;
      server.ccode = ccode;
      state.me.selectedServer = action.data.server;
      return state;

    case authConstants.SIGN_OUT_REQUEST:
    case authConstants.DELETE_ACCOUNT_SUCCESS:
      incognitoLocalStorage.removeItem(`Breadcrumbs_auth`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_apis`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_apps`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_leaks`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_locations`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_notifications`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_profile_details`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_profile_detail_questions`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_sales`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_posts`)
      // incognitoLocalStorage.removeItem(`Breadcrumbs_servers`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_source_requests`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_user_uploads`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_offers`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_buyers`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_offer_responses`)
      incognitoLocalStorage.removeItem(`Breadcrumbs_weather_snapshots`)
      return {
        apis: {},
        apps: {},
        leaks: {},
        locations: {},
        me: {
          id: '',
          email: '',
          available_balance: '',
          email_validated: '',
          first_name: '',
          last_name: '',
          created_at: '',
          last_post_id: '',
          current_weather_data: '',
          current_heartrate: '',
          is_awake: '',
          profile_type: '',
          current_location_id: '',
          location_app_key: '',
          timeline_orientation: '',
          timeline_interface: '',
          timeline_beta: '',
          selectedServerId: ((state || {}).me || {}).selectedServerId, // if VPN is still connected, persist this value on re-login
          selected_api_ids: '',
          client_version_number: '',
          badge_count: '',
          phone_number: '',
          has_onboarded: '',
          has_password: '',
          leakage_last_fetch: '',
        },
        notifications: {},
        profile_details: {},
        profile_detail_questions: [],
        sales: {},
        posts: {},
        servers: []
      };

    case userConstants.DELETE_APP_SUCCESS:
      state.apps = { ...state.apps, ...action.data.apps };
      state.posts = { ...state.posts, ...action.data.posts };
      return state;

    case userConstants.TOGGLE_APP_OPTION_SUCCESS:
      state.apps = { ...state.apps, ...action.data.apps };
      return state;

    case userConstants.UPLOAD_RECEIPT_SUCCESS:
      state.user_uploads = { ...state.user_uploads, ...action.data.user_uploads };
      return state;

    case userConstants.NEW_SOURCE_SUCCESS:
      state.source_requests = { ...state.source_requests, ...action.data.source_requests };
      return state;

    case userConstants.SUBMIT_SURVEY_RESPONSE_SUCCESS:
      state.survey_responses = { ...state.survey_responses, ...action.data.survey_responses };
      state.posts = { ...state.posts, ...action.data.posts };
      return state;

    case userConstants.LINK_POST_TO_LOCATION_SUCCESS:
      state.posts = { ...state.posts, ...action.data.posts };
      state.notifications = { ...state.notifications, ...action.data.notifications };
      return state;

    case userConstants.MARK_NOTIFICATION_AS_ACCEPTED_SUCCESS:
      state.notifications = { ...state.notifications, ...action.data.notifications };
      return state;

    case userConstants.UPDATE_TIMELINE_ORIENTATION_SUCCESS:
    case userConstants.UPDATE_TIMELINE_INTERFACE_SUCCESS:
    case userConstants.Update_TIMELINE_BETA_SUCCESS:
    case userConstants.UPDATE_ACCOUNT_PREFERENCE_SUCCESS:
    case userConstants.UPDATE_WEATHER_DISPLAY_SUCCESS:
    case userConstants.UPDATE_HEARTRATE_DISPLAY_SUCCESS:
      state.me = { ...state.me, ...action.data.me };
      return state;

    case userConstants.CLEAR_NOTIFICATIONS_SUCCESS:
      state.me = { ...state.me, badge_count: 0 };
      return state;

    case userConstants.UPDATE_INFO_DISPLAY_REQUEST:
      return { ...state, info_display: action.data };

    case userConstants.ADD_POLYLINES_UPDATE:
      state.posts = { ...state.posts, ...action.data.posts };
      state.me.polylinesGenerated = true;
      return state;

    case userConstants.ADD_VPN_SIDEBAR_HEIGHTS_UPDATE:
      state.servers = action.data;
      state.me.vpnSideBarHeightsAdded = true;
      return state;

    case userConstants.ADD_SIDEBAR_HEIGHTS_UPDATE:
      state.posts = { ...state.posts, ...action.data };
      state.me.sideBarHeightsAdded = true;
      return state;

    case authConstants.REPORT_CLIENT_ERROR_REQUEST:
      state.me.polylinesGenerated = false;
      state.me.vpnSideBarHeightsAdded = false;
      state.me.sideBarHeightsAdded = false;
      state.apis = {};
      state.apps = {};
      state.leaks = {};
      state.locations = {};
      state.notifications = {};
      state.profile_details = {};
      state.profile_detail_questions = [];
      state.sales = {};
      state.posts = {};
      state.user_uploads = {};
      state.source_requests = {};
      state.servers = [];
      return state;

    case authConstants.VALIDATE_PHONE_SUCCESS:
      state.me.phone_validated = true;
      return state;

    case authConstants.UPDATE_PHONE_SUCCESS:
      state.me.phone_number = action.data.phoneNumber;
      return state;

    case authConstants.ACKNOWLEDGE_MK_REQUEST:
      state.me.isNewSignup = false;
      state.me.invalidBCMK = null;
      state.me.bcmk = null;
      return state;

    case authConstants.CONFIRM_BCMK_REQUEST:
      state.me.invalidBCMK = null;
      return state;

    case userConstants.UPDATE_LAST_NOTIFICATION_ID_REQUEST:
      state.me.last_notification_id = action.data.lastNotificationId;
      return state;

    case userConstants.RESPOND_TO_OFFER_SUCCESS:
      state.offer_responses = { ...state.offer_responses, ...action.data.offer_responses };
      return state;

    case userConstants.RESPOND_TO_OFFER_DEMO:
      state.offer_responses[action.data.id] = { offer_id: action.data.id }
      return state;

    case userConstants.SUBMIT_PROFILE_DETAIL_SUCCESS:
      state.profile_details = { ...state.profile_details, ...action.data.profile_details };
      return state;

    case userConstants.SUBMIT_PROFILE_DETAIL_DEMO:
      state.profile_details[action.data.id] = { question_id: action.data.id }
      return state;

    case userConstants.UPDATE_ONBOARDING_STATUS_DEMO:
      state.me['has_onboarded'] = '1'
      return state;

    case userConstants.UPDATE_SELECTED_SERVER_REQUEST:
      state.me['selectedServerId'] = action.data.serverId;
      return state;

    case userConstants.UPDATE_ONBOARDING_STATUS_SUCCESS:
      state.me['has_onboarded'] = '1';
      return state;

    case userConstants.SUBMIT_FEEDBACK_REQUEST:
      state.me['should_give_feedback'] = false;
      return state;

    default:
      return state;
  }
}
