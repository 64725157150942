// environmentConstants.js

// Determine the host from window.location if available.
const currentHost = typeof window !== 'undefined' ? window.location.hostname : '';

const isAndroidEmulator = currentHost === '10.0.2.2';

export const environmentConstants = {
  SERVER_URL: process.env.NODE_ENV === 'development'
    ? (isAndroidEmulator ? 'http://10.0.2.2:8000' : 'http://localhost:8000')
    : 'https://www.breaddata.co',
  FRONTEND_URL: process.env.NODE_ENV === 'development'
    ? (isAndroidEmulator ? 'http://10.0.2.2:3000' : 'http://localhost:3000')
    : 'https://www.breaddata.co',
  MONTHS: {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December"
  }
};
